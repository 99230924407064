<template>
    <section class="h5 font-weight-medium">
        <span>ADDRESS</span>
        <span class="float-right"><el-button icon="el-icon-edit" circle @click="editMode=true"></el-button></span>
    </section>
    <hr />
    <template v-if="address!=null&&editMode">
        <div class="row">
            <div class="col-12 col-sm-6">
                <el-form :model="address" :rules="addressRules" ref="addressForm" :label-position="top" hide-required-asterisk="true" show-message="false" @submit.prevent>
                    <section>
                        <el-form-item label="TIMEZONE" prop="timeZone">
                            <el-select v-model="address.timeZone" v-fancylabel>
                                <el-option v-for="item in timezones"
                                           :key="item.id"
                                           :label="item.name"
                                           :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </section>
                    <section>
                        <el-form-item label="ADDRESS LINE 1" prop="addressLine1">
                            <el-input type="text" v-model="address.addressLine1" autocomplete="off" v-focus v-fancylabel v-on:keyup.enter="submitChanges"></el-input>
                        </el-form-item>
                    </section>
                    <section>
                        <el-form-item label="ADDRESS LINE 2" prop="addressLine2">
                            <el-input type="text" v-model="address.addressLine2" autocomplete="off" v-fancylabel v-on:keyup.enter="submitChanges"></el-input>
                        </el-form-item>
                    </section>
                    <section>
                        <el-form-item label="CITY" prop="city">
                            <el-input type="text" v-model="address.city" autocomplete="off" v-fancylabel v-on:keyup.enter="submitChanges"></el-input>
                        </el-form-item>
                    </section>
                    <section>
                        <el-form-item label="STATE" prop="state">
                            <el-select v-model="address.state" v-fancylabel>
                                <el-option v-for="item in states"
                                           :key="item.id"
                                           :label="item.name"
                                           :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </section>
                    <section>
                        <el-form-item label="ZIPCODE" prop="zipCode">
                             <el-input type="text" v-model="address.zipCode" autocomplete="off" v-fancylabel v-on:keyup.enter="submitChanges"></el-input>
                        </el-form-item>
                    </section>
                    <!--<section>
                        <el-form-item label="COUNTRY" >
                            <el-select v-model="address.country" disabled v-fancylabel>
                                <el-option   key="'USA'"
                                           label="'USA'"
                                           value="'USA'"></el-option>
                            </el-select>
                        </el-form-item>
                    </section>-->
                </el-form>
            </div>
        </div>
        <div class="">
            <el-form-item>
                <el-button type="primary" plain @click="editMode=false" class="float-left">CANCEL</el-button>
                <el-button type="primary" @click="submitChanges" :loading="loading" class="float-left">SUBMIT CHANGES</el-button>
            </el-form-item>
        </div>
    </template>
    <template v-if="address!=null&&!editMode">
        <div class="row">
            <div class="col-12">
                <section>
                    <div class="font-weight-medium">
                        TIMEZONE
                    </div>
                    <div class="mt-2">
                        {{address.timeZone}}
                    </div>
                </section>
                <section>
                    <div class="font-weight-medium">
                        ADDRESS LINE 1
                    </div>
                    <div class="mt-2">
                        {{address.addressLine1}}
                    </div>
                </section>
                <section>
                    <div class="font-weight-medium">
                        ADDRESS LINE 2
                    </div>
                    <div class="mt-2">
                        {{address.addressLine2}}
                    </div>
                </section>
                <section>
                    <div class="font-weight-medium">
                        CITY
                    </div>
                    <div class="mt-2">
                        {{address.city}}
                    </div>
                </section>
                <section>
                    <div class="font-weight-medium">
                        STATE
                    </div>
                    <div class="mt-2">
                        {{address.state}}
                    </div>
                </section>
                <section>
                    <div class="font-weight-medium">
                        ZIPCODE
                    </div>
                    <div class="mt-2">
                        {{address.zipCode}}
                    </div>
                </section>
                <section>
                    <div class="font-weight-medium">
                        COUNTRY
                    </div>
                    <div class="mt-2">
                        {{address.country}}
                    </div>
                </section>
            </div>
        </div>
    </template>

</template>

<script>
    import axios from "axios"

    export default {
        name: 'Address',
        components: {  },
        data() {
            return {
                editMode: false,
                loading: false,
                file: null,
                address:null,
                addressRules: {
                    timeZone: [
                        { required: true, message: 'Time zone is required', trigger: 'blur' }
                    ],
                    addressLine1: [
                        { required: true, message: 'Address line 1 is required', trigger: 'blur' }
                    ],
                    city: [
                        { required: true, message: 'City is required', trigger: 'blur' }
                    ],
                    state: [
                        { required: true, message: 'State is required', trigger: 'blur' }
                    ],
                    zipCode: [
                        { required: true, message: 'Zip code is required', trigger: 'blur' }
                    ],
                    country: [
                        { required: true, message: 'Country is required', trigger: 'blur' }
                    ]
                },
                timezones: [{ id: "Alabama:UTC-05:00", name: "Alabama:UTC-05:00" },
                     { id: "Alaska:UTC-08:00", name: "Alaska:UTC-08:00" },
                     { id: "Alaska:UTC-09:00", name: "Alaska:UTC-09:00" },
                     { id: "Arizona:UTC-06:00", name: "Arizona:UTC-06:00" },
                     { id: "Arkansas:UTC-05:00", name: "Arkansas:UTC-05:00" },
                     { id: "California:UTC-07:00", name: "California:UTC-07:00" },
                     { id: "Colorado:UTC-06:00", name: "Colorado:UTC-06:00" },
                     { id: "Connecticut:UTC-04:00", name: "Connecticut:UTC-04:00" },
                     { id: "Delaware:UTC-04:00", name: "Delaware:UTC-04:00" },
                     { id: "District of Columbia:UTC-04:00", name: "District of Columbia:UTC-04:00" },
                     { id: "Florida:UTC-04:00", name: "Florida:UTC-04:00" },
                    { id: "Florida:UTC-05:00", name: "Florida:UTC-05:00" },
                    { id: "Georgia:UTC-04:00", name: "Georgia:UTC-04:00" },
                    { id: "Idaho:UTC-06:00", name: "Idaho:UTC-06:00" },
                    { id: "Idaho:UTC-07:00", name: "Idaho:UTC-07:00" },
                    { id: "Illinois:UTC-05:00", name: "Illinois:UTC-05:00" },
                    { id: "Indiana:UTC-04:00", name: "Indiana:UTC-04:00" },
                    { id: "Indiana:UTC-05:00", name: "Indiana:UTC-05:00" },
                    { id: "Iowa:UTC-05:00", name: "Iowa:UTC-05:00" },
                    { id: "Kansas:UTC-05:00", name: "Kansas:UTC-05:00" },
                    { id: "Kansas:UTC-06:00", name: "Kansas:UTC-06:00" },
                    { id: "Kentucky:UTC-04:00", name: "Kentucky:UTC-04:00" },
                    { id: "Kentucky:UTC-05:00", name: "Kentucky:UTC-05:00" },
                    { id: "Louisiana:UTC-05:00", name: "Louisiana:UTC-05:00" },
                    { id: "Maine:UTC-04:00", name: "Maine:UTC-04:00" },
                    { id: "Maryland:UTC-04:00", name: "Maryland:UTC-04:00" },
                    { id: "Massachusetts:UTC-04:00", name: "Massachusetts:UTC-04:00" },
                    { id: "Michigan:UTC-04:00", name: "Michigan:UTC-04:00" },
                    { id: "Michigan:UTC-05:00", name: "Michigan:UTC-05:00" },
                    { id: "Minnesota:UTC-05:00", name: "Minnesota:UTC-05:00" },
                    { id: "Mississippi:UTC-05:00", name: "Mississippi:UTC-05:00" },
                    { id: "Missouri:UTC-05:00", name: "Missouri:UTC-05:00" },
                    { id: "Montana:UTC-06:00", name: "Montana:UTC-06:00" },
                    { id: "Nebraska:UTC-05:00", name: "Nebraska:UTC-05:00" },
                    { id: "Nebraska:UTC-06:00", name: "Nebraska:UTC-06:00" },
                    { id: "Nevada:UTC-07:00", name: "Nevada:UTC-07:00" },
                    { id: "New Hampshire:UTC-04:00", name: "New Hampshire:UTC-04:00" },
                    { id: "New Jersey:UTC-04:00", name: "New Jersey:UTC-04:00" },
                    { id: "New Mexico:UTC-06:00", name: "New Mexico:UTC-06:00" },
                    { id: "New York:UTC-04:00", name: "New York:UTC-04:00" },
                    { id: "North Carolina:UTC-04:00", name: "North Carolina:UTC-04:00" },
                    { id: "North Dakota:UTC-05:00", name: "North Dakota:UTC-05:00" },
                    { id: "North Dakota:UTC-06:00", name: "North Dakota:UTC-06:00" },
                    { id: "Ohio:UTC-04:00", name: "Ohio:UTC-04:00" },
                    { id: "Oklahoma:UTC-05:00", name: "Oklahoma:UTC-05:00" },
                    { id: "Oregon:UTC-07:00", name: "Oregon:UTC-07:00" },
                    { id: "Oregon:UTC-06:00", name: "Oregon:UTC-06:00" },
                    { id: "Pennsylvania:UTC-04:00", name: "Pennsylvania:UTC-04:00" },
                    { id: "Rhode Island:UTC-04:00", name: "Rhode Island:UTC-04:00" },
                    { id: "South Carolina:UTC-04:00", name: "South Carolina:UTC-04:00" },
                    { id: "South Dakota:UTC-05:00", name: "South Dakota:UTC-05:00" },
                    { id: "South Dakota:UTC-06:00", name: "South Dakota:UTC-06:00" },
                    { id: "Tennessee:UTC-05:00", name: "Tennessee:UTC-05:00" },
                    { id: "Tennessee:UTC-04:00", name: "Tennessee:UTC-04:00" },
                    { id: "Texas:UTC-05:00", name: "Texas:UTC-05:00" },
                    { id: "Texas:UTC-06:00", name: "Texas:UTC-06:00" },
                    { id: "Utah:UTC-06:00", name: "Utah:UTC-06:00" },
                    { id: "Vermont:UTC-04:00", name: "Vermont:UTC-04:00" },
                    { id: "Virginia:UTC-04:00", name: "Virginia:UTC-04:00" },
                    { id: "Washington:UTC-07:00", name: "Washington:UTC-07:00" },
                    { id: "West Virginia:UTC-04:00", name: "West Virginia:UTC-04:00" },
                    { id: "Wisconsin:UTC-05:00", name: "Wisconsin:UTC-05:00" },
                    { id: "Wyoming:UTC-06:00", name: "Wyoming:UTC-06:00" }],
                states: [
                    { name: "Alabama", id: "AL" },
                    { name: "Alaska", id: "AK" },
                    { name: "Arizona", id: "AZ" },
                    { name: "Arkansas", id: "AR" },
                    { name: "California", id: "CA" },
                    { name: "Colorado", id: "CO" },
                    { name: "Connecticut", id: "CT" },
                    { name: "Delaware", id: "DE" },
                    { name: "District of Columbia", id: "DC" },
                    { name: "Florida", id: "FL" },
                    { name: "Georgia", id: "GA" },
                    { name: "Hawaii", id: "HI" },
                    { name: "Idaho", id: "ID" },
                    { name: "Illinois", id: "IL" },
                    { name: "Indiana", id: "IN" },
                    { name: "Iowa", id: "IA" },
                    { name: "Kansas", id: "KS" },
                    { name: "Kentucky", id: "KY" },
                    { name: "Louisiana", id: "LA" },
                    { name: "Maine", id: "ME" },
                    { name: "Maryland", id: "MD" },
                    { name: "Massachusetts", id: "MA" },
                    { name: "Michigan", id: "MI" },
                    { name: "Minnesota", id: "MN" },
                    { name: "Mississippi", id: "MS" },
                    { name: "Missouri", id: "MO" },
                    { name: "Montana", id: "MT" },
                    { name: "Nebraska", id: "NE" },
                    { name: "Nevada", id: "NV" },
                    { name: "New Hampshire", id: "NH" },
                    { name: "New Jersey", id: "NJ" },
                    { name: "New Mexico", id: "NM" },
                    { name: "New York", id: "NY" },
                    { name: "North Carolina", id: "NC" },
                    { name: "North Dakota", id: "ND" },
                    { name: "Ohio", id: "OH" },
                    { name: "Oklahoma", id: "OK" },
                    { name: "Oregon", id: "OR" },
                    { name: "Pennsylvania", id: "PA" },
                    { name: "Rhode Island", id: "RI" },
                    { name: "South Carolina", id: "SC" },
                    { name: "South Dakota", id: "SD" },
                    { name: "Tennessee", id: "TN" },
                    { name: "Texas", id: "TX" },
                    { name: "Utah", id: "UT" },
                    { name: "Vermont", id: "VT" },
                    { name: "Virginia", id: "VA" },
                    { name: "Washington", id: "WA" },
                    { name: "West Virginia", id: "WV" },
                    { name: "Wisconsin", id: "WI" },
                    { name: "Wyoming", id: "WY" }
                ]
            }
        },
        methods: {
            submitChanges() {
                this.address.country = "USA";
                this.$refs["addressForm"].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        axios.post("/api/users/updateaddress", this.address).then(response => {
                            this.editMode = false;
                            this.loading = false;
                        }).catch((error) => {
                            this.loading = false;
                        });
                    }
                });
            }
        },
        computed: {
           
        },
        mounted() {
          
        },
        created() {
            axios.get("/api/users/getaddress").then(response => {
                this.address = response.data;
                if (this.address.country == null)
                    this.editMode = true;
            }).catch((error) => {
            });
        }
    }
</script>


<style>
  
</style>
