<template>
    <div class="cart">
        <div class="text-center font-weight-bold p-5 bg-dark text-white h1 mt-5">YOUR CART</div>
        <div class="h4 text-center font-weight-bold mt-5" v-if="cart==undefined||cart.total == 0">
            YOUR CART IS EMPTY
        </div>
        <div class="mt-3 text-center h3 p-3">
            <router-link :to="storeLink" class="nav-link" style="color:#4d4d4d;text-decoration: underline; font-size:larger">Continue Shopping</router-link>
        </div>

        <div class="container">
            <div class="row no-gutters">
                <div class="col-12 col-lg-7">
                    <template v-for="product in cart.products" :key="product.id">
                        <div class="row mb-5 prod-item no-gutters">
                            <div class="col-3">
                                <div class="" style="max-width:120px">
                                    <el-image :src="product.imageSrc">
                                        <template #error>
                                            <div class="image-slot">
                                                <i class="el-icon-picture-outline"></i>
                                            </div>
                                        </template>
                                    </el-image>
                                </div>
                            </div>
                            <div class="col-7">
                                <div class="h5 font-weight-bold">{{product.name}}</div>
                                <div class="h6">SIZE: {{product.quantity}}-BAGS</div>
                                <div class="counter mt-4 mb-4" style="">
                                    <span class="">
                                        <a class="min c-pointer" @click="setCount(product,-1)"><i class="el-icon-minus"></i></a>
                                    </span>
                                    <span class="count-box-mul h5 pr-3 pl-3">
                                        {{product.count}}
                                    </span>
                                    <span class="">
                                        <a class="plus c-pointer" @click="setCount(product,1)"><i class="el-icon-plus"></i></a>
                                    </span>
                                </div>
                                <div class="h6 mt-2 c-pointer d-none d-sm-block" style="color:#409EFF" @click="product.showFlavors=!product.showFlavors">FLAVORS
                                <i class="el-icon-caret-bottom" v-if="product.showFlavors==false"></i>
                                <i class="el-icon-caret-top" v-if="product.showFlavors==true"></i></div>
                                <div class="d-none d-sm-block">
                                    <el-collapse-transition>
                                        <div v-show="product.showFlavors==true">
                                            <div class="row" v-for="bag in product.bags" :key="bag.id">
                                                <div class="col-2">
                                                    <div class="" style="max-width:60px">
                                                        <el-image :src="bag.imageSrc">
                                                            <template #error>
                                                                <div class="image-slot">
                                                                    <i class="el-icon-picture-outline"></i>
                                                                </div>
                                                            </template>
                                                        </el-image>
                                                    </div>
                                                </div>
                                                <div class="h6 col-6">{{bag.name}}</div>
                                                <div class="h6 col-2">{{bag.count}}</div>
                                                <div class="h6 col-2">${{parseFloat(bag.total).toFixed(2)}}</div>
                                            </div>
                                        </div>
                                    </el-collapse-transition>
                                </div>
                            </div>
                            <div class="col-2 text-right">
                                <div class="h4 c-pointer font-weight-bold text-right" @click="removeProduct(product)"><i class="el-icon-close"></i></div>
                                <div class="h5 mt-3 text-right">${{parseFloat(product.total).toFixed(2)}}</div>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="col-12 col-lg-4 offset-lg-1 mb-3 border rounded" v-if="cart!=undefined&&cart.total > 0">
                    <OrderReview></OrderReview>
                </div>
                <div class="row checkout-action w-100 no-gutters" style="margin:0 auto" v-if="cart!=undefined&&cart.products!=undefined&&cart.products.length>0">
                    <div class="col-12 col-lg-7">
                        <el-button type="danger" style="background-color: #983222" @click="checkout">CHECKOUT</el-button>
                    </div>
                   
                </div>
            </div>

        </div>
    </div>
 
</template>

<script>

    import store from "@/store"
    import router from "../router";
    import OrderReview from "./OrderReview"

    export default {
        name: "Cart",
        components: { OrderReview},
        data() {
            return {
                cart: {}
            }
        },
        computed: {
            storeLink() {
                return '/store/public/' + store.getters.storeCode;
            }
        },
        methods: {
            setCount(product, v) {   
                product.count = parseInt(product.count)
                if (product.count + v > 0) {
                    product.count = parseInt(product.count) + v;
                    product.total = product.count * parseFloat(product.price);
                    this.recalculateCartTotal();
                    store.dispatch('setCart', this.cart);
                }                
            },
            recalculateCartTotal() {
                let total = 0;
                this.cart.products.forEach(product => {
                    total += parseFloat(product.total);
                });
                this.cart.total = total;
            },
            removeProduct(product) {
                const index = this.cart.products.indexOf(product);
                if (index > -1) {
                    this.cart.products.splice(index, 1);
                    this.recalculateCartTotal();
                    store.dispatch('setCart', this.cart);
                }
            },
            checkout() {
                router.push('/checkout');
            }
        },
        mounted() {
            this.cart = store.getters.cart;
            if (this.cart != undefined) {
                this.cart.products.forEach(p => {
                    p.showFlavors = false;
                });
            }
        }
    }
</script>

<style scoped>
    img {
        max-height: 95px;
    }
    .prod-item{
        border-bottom:1px solid #d4d4d4;
    }
    .counter a.min,.counter a.plus{
        border-radius:100%;
        border:1px solid #d4d4d4;
        padding:5px;
    }
    .checkout-action button{
        width:100%;
        border-radius:1px;
    }
</style>