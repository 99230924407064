<template>
    <div class="container" :loading="loading">
        <div class="row no-gutters center" style="justify-content:center" v-if="!editMode">
            <div class="h2 font-weight-bold pt-2 pb-2 p-sm-0 pb-sm-3 elipssis center">
                <a style="justify-content: center;" :href="event.url">{{eventEdit.title}}</a>
            </div>
        </div>
        <div class="row no-gutters">
            <div class="col image-cover">
                <el-image :src="event.imageSrc" v-if="!editMode">
                    <template #error>
                        <div class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </template>
                </el-image>
                <ImageUploader @crop="setCropData" @image-selected="setSelectedImage" v-if="editMode" :image="event.imageSrc"></ImageUploader>
            </div>
        </div>
        <div class="row">
            <div class="col-12" style="justify-content:center">{{eventEdit.description}}</div>
        </div>
        <div class="row no-gutters mt-lg-5" v-if="editMode">
            <div class="col-12">
                <el-form :model="eventEdit" :rules="eventEditRules" ref="eventEditForm" :label-position="top" hide-required-asterisk="true">
                    <el-form-item label="TITLE" prop="title">
                        <el-input v-model="eventEdit.title" v-fancylabel v-on:keyup.enter="saveChanges"></el-input>
                    </el-form-item>
                    <el-form-item label="DESCRIPTION" prop="description">
                        <el-input v-model="eventEdit.description" v-fancylabel v-on:keyup.enter="saveChanges"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <hr />
        <div class="row">
            <h5 class="ml-3">Okay your fundraiser hasn't started yet, but you can still edit the Fundraiser Picture and also Title and Description with the Edit Fundrasier button below.  Once the Fundraiser is live more information will be populated here.</h5>
        </div>
        <div class="row">
            <div class="col-12">
                <label class="top-label">Event Information</label>
                <div class="title">
                    <div style="overflow: hidden;">
                        <div class="row">
                            <div class="col-4"><h6>Edit Fundraiser:</h6></div>
                            <div class="col-8">
                                <el-button-group>
                                    <el-button style="width:150px" class="mr-2" type="primary" size="small" icon="el-icon-edit-outline" @click="editMode=true" v-if="!editMode" title="Edit Fundraiser, this button allows you to make changes to Fundraiser title, Description and Event Picture!">Edit Fundraiser</el-button>
                                    <el-button style="width:150px" class="mr-2" type="primary" size="small" icon="el-icon-check" :loading="loading" @click="saveChanges" v-if="editMode" title="Save Changes to Store!">Save Changes</el-button>
                                </el-button-group>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 font-weight-bold"><h6 class="font-weight-bold">Fundraiser Title:</h6></div>
                            <div class="col-8 text-left"><h6>{{eventEdit.title}}</h6></div>
                        </div>
                        <div class="row">
                            <div class="col-4 font-weight-bold"><h6 class="font-weight-bold">Fundraiser Description:</h6></div>
                            <div class="col-8 text-left"><h6>{{eventEdit.description}}</h6></div>
                        </div>
                        <div class="row">
                            <div class="col-4 font-weight-bold"><h6 class="font-weight-bold">Fundraiser Code:</h6></div>
                            <span class="col-8 text-left" id="eventCode" :data-copy="event.url"><h6>{{event.code}}</h6></span>
                        </div>
                        <div class="row">
                            <div class="col-4 font-weight-bold"><h6 class="font-weight-bold">Fundraiser Organizer Email:</h6></div>
                            <span class="col-8 text-left"><h6>{{profile.email}}</h6></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <label class="top-label">Fundraiser Statistics</label>
                <div class="title">
                    <div style="overflow: hidden; ">
                        <div class="row">
                            <div class="col-4"><h6 class="font-weight-bold">START DATE:</h6></div>
                            <div class="col-8"><h6>{{new Date(event.startDate).toLocaleDateString("en-US",{  year: 'numeric', month: 'long', day: 'numeric' })}}</h6></div>
                        </div>
                        <div class="row">
                            <div class="col-4"><h6 class="font-weight-bold">END DATE:</h6></div>
                            <div class="col-8"><h6>{{new Date(event.endDate).toLocaleDateString("en-US",{  year: 'numeric', month: 'long', day: 'numeric' })}}</h6></div>
                        </div>
                        <hr />
                        <div class="statistics">
                            <div class="row">
                                <div class="col-4"><h6 class="font-weight-bold">Fundraiser Target:</h6></div>
                                <div class="col-8 text-left"><h6>${{event.targetAmount}}</h6></div>
                            </div>
                            <div class="row">
                                <div class="col-4"><h6 class="font-weight-bold">Total Collected:</h6></div>
                                <div class="col-8 text-left"><h6>${{event.allStoresTotal}}</h6></div>
                            </div>
                            <div class="row">
                                <div class="col-4"><h6 class="font-weight-bold">Orgainizer Store Total:</h6></div>
                                <div class="col-8 text-left"><h6>${{event.organizerStoreTotal}}</h6></div>
                            </div>
                            <div class="row">
                                <div class="col-4"><h6 class="font-weight-bold">Fundraiser Subtotal (total-fees):</h6></div>
                                <div class="col-8 text-left"><h6>${{event.organizerStoreSubTotal}}</h6></div>
                            </div>
                            <div class="row">
                                <div class="col-4"><h6 class="font-weight-bold">Elgible Payout Amount:</h6></div>
                                <div class="col-8 text-left"><h6>${{Math.round((event.organizerStoreSubTotal * 100) / 100) / 2}}</h6></div>
                            </div>
                            <div class="row">
                                <div class="col-4"><h6 class="font-weight-bold">Number of Participants:</h6></div>
                                <div class="col-8 text-left"><h6>{{event.participantsCount}}</h6></div>
                            </div>
                            <div class="row">
                                <div class="col-4"><h6 class="font-weight-bold">Number of Donations:</h6></div>
                                <div class="col-8 text-left"><h6>{{event.donationsCount}}</h6></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <label class="top-label">PARTICIPANTS</label>
                <el-badge :value="event.participantsCount" v-if="parseInt(event.participantsCount)>0" class="count">
                </el-badge>
                <div class="participants">
                    <div class="row m-0">
                        <div class="col-6"></div>
                        <div class="col-3 font-weight-bold text-right" style="font-size:14px">TARGET</div>
                        <div class="col-3  font-weight-bold text-right" style="font-size:14px">COLLECTED</div>
                    </div>
                    <hr class="m-1" />
                    <div style="overflow: auto; height: 250px; ">
                        <div class="row pt-1 pb-1 no-gutters" v-for="participant in participants" :key="participant.name">
                            <div class="col-6 elipssis pl-0">{{participant.name}}</div>
                            <div class="col-3 text-right">${{participant.targetAmount}}</div>
                            <div class="col-3 text-right">${{participant.amount}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <label class="top-label">BUYERS</label>
                <el-badge :value="event.donatorsCount" v-if="parseInt(event.donatorsCount)>0" class="count">
                </el-badge>
                <div class="donatros">
                    <div class="row">
                        <div class="col-8"></div>
                        <div class="col-4 font-weight-bold text-right" style="font-size:14px">DONATION</div>
                    </div>
                    <hr class="m-1" />
                    <div style="overflow: auto; height: 250px; ">
                        <div class="row pt-1 pb-1 no-gutters" v-for="donator in donators" :key="donator.name">
                            <div class="col-8 elipssis">{{donator.name}}</div>
                            <div class="col-4 text-right">${{donator.amount}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr />
        <div class="row">
            <div class="col-12 col-lg-4 pl-0">
                <label class="font-weight-bold">Fundraiser Goal chart:</label>
                <DoughnutChart v-bind:chartData="eventChartData" v-bind:chartOptions="chartOptions" :key="eventChartData.labels" />
            </div>
            <div class="col-12 col-lg-4 pl-0">
                <label class="font-weight-bold">Participant totals chart:</label>
                <PieChart v-bind:chartData="participantsChartData" v-bind:chartOptions="chartOptions" :key="participantsChartData.labels" />
            </div>
            <div class="col-12 col-lg-4 pl-0">
                <label class="font-weight-bold">Donator totals chart:</label>
                <PieChart v-bind:chartData="donatorsChartData" v-bind:chartOptions="chartOptions" :key="donatorsChartData.labels" />
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios"
    import { copyToClipboard } from "../assets/js/app.js";
    import ImageUploader from "./ImageUploader"
    import PieChart from "./PieChart"
    import DoughnutChart from './DoughnutChart.vue'
    import ChartDataLabels from 'chartjs-plugin-datalabels';
    import { mapGetters } from "vuex"

    export default {
        name: "EventDetail",
        components: { ImageUploader, PieChart, DoughnutChart },
        data() {
            return {
                event: {},
                eventEdit: {
                    imageCropData: {}
                },
                file: null,
                loading: false,
                editMode: false,
                eventEditRules: {
                    title: [
                        { required: true, message: 'Title is required', trigger: 'change' }
                    ],
                    description: [
                        { required: true, message: 'Description reason is required', trigger: 'change' }
                    ]
                },
                profile: {
                    email: '',
                    firstName: '',
                    lastName: '',                                     
                    imageSrc: '',
                    imageCropData: {}                  
                },
                chartOptions: {
                    responsive: true,
                    animation: {
                        duration: 0
                    },
                    hover: {
                        animationDiration: 0
                    },
                    responsiveAnimationDuration: 0,
                    plugins: {
                        ChartDataLabels: {
                            labels: [
                                {
                                    render: 'label',
                                    position: 'outside'
                                },
                                {
                                    render: 'percentage'
                                }
                            ]
                        }
                    }
                }               
            }
        },
        computed: {
            participants() {
                let ps = [];
                if (this.event.participants != null) {
                    this.event.participants.split(',').forEach(p => {
                        let items = p.split('_');
                        ps.push({ name: items[0], targetAmount: items[1], amount: items[2] });
                    });
                }
                return ps;
            },
            donators() {
                let dn = [];
                if (this.event.donators != null) {
                    this.event.donators.split(',').forEach(p => {
                        let items = p.split('_');
                        dn.push({ name: items[0], amount: items[1] });
                    });
                }
                return dn;
            },
            eventChartData() {
                let datatemplate = {
                    labels: [], datasets: [{
                        label: 'Event Totals',
                        data: [], backgroundColor: []
                    }]
                };
                if (this.event != null) {
                    let percentage = ((this.event.allStoresTotal * 100) / this.event.targetAmount);
                    datatemplate.labels.push('All Participant Stores Goal: $' + this.event.targetAmount);
                    datatemplate.datasets[0].data.push(100 - Math.round(percentage));
                    datatemplate.datasets[0].backgroundColor.push('#E46651');

                    datatemplate.labels.push('Event Dollars raised: $' + this.event.allStoresTotal);
                    datatemplate.datasets[0].data.push(Math.round(percentage));
                    datatemplate.datasets[0].backgroundColor.push('#41B883');
                }
                return datatemplate;
            },
            participantsChartData() {
                let datatemplate = {
                    labels: [], datasets: [{
                        data: [], backgroundColor: []
                    }]
                };
                if (this.event.participants != null) {
                    this.event.participants.split(',').forEach(p => {
                        if (p != null) {
                            let items = p.split('_');
                            if (items != null) {
                                var letters = '0123456789ABCDEF';
                                var color = '#';
                                for (var i = 0; i < 6; i++) {
                                    color += letters[Math.floor(Math.random() * 16)];
                                }
                                datatemplate.labels.push(items[0].trim());
                                datatemplate.datasets[0].data.push(items[2]);
                                datatemplate.datasets[0].backgroundColor.push(color);
                            }
                        }
                    });
                }
                return datatemplate;
            },
            donatorsChartData() {
                let datatemplate = {
                    labels: [], datasets: [{
                        data: [], backgroundColor: []
                    }]
                };
                if (this.event.donators != null) {
                    this.event.donators.split(',').forEach(p => {
                        if (p != null) {
                            let items = p.split('_');
                            if (items != null) {
                                var letters = '0123456789ABCDEF';
                                var color = '#';
                                for (var i = 0; i < 6; i++) {
                                    color += letters[Math.floor(Math.random() * 16)];
                                }
                                datatemplate.labels.push(items[0].trim());
                                datatemplate.datasets[0].data.push(items[1].toString());
                                datatemplate.datasets[0].backgroundColor.push(color);
                            }
                        }
                    });
                }
                return datatemplate;
            },
            ...mapGetters([
                'currentUserDetail'
            ])
        },       
        created() {
            axios.get('/api/events/detail/' + this.$route.params.id).then(response => {
                this.event = response.data;
                this.eventEdit.id = response.data.id;
                this.eventEdit.title = this.event.title;
                this.eventEdit.description = this.event.description;
            }).catch((error) => {

            });

            this.profile.email = this.currentUserDetail.email;
            this.profile.firstName = this.currentUserDetail.firstName;
            this.profile.lastName = this.currentUserDetail.lastName;
            this.profile.imageSrc = this.currentUserDetail.imageSrc;
        },        
        methods: {
            shareOnFacebook() {
                window.open("https://www.facebook.com/sharer/sharer.php?u=" + this.event.publicUrl, "_blank");
            },
            shareOnTwitter() {
                window.open("https://twitter.com/share?url=" + this.event.publicUrl, "_blank");
            },
            copyCode() {
                copyToClipboard("eventCode");
            },
            setSelectedImage(file) {
                this.file = file;
            },
            setCropData(data) {
                this.eventEdit.imageCropData = data;
            },
            inviteParticipants() {
                this.loading = true;
                var emails = [];
                this.invitedParticipants.forEach(p => {
                    if (p != '')
                        emails.push(p.email);
                });

                axios.post('/api/participants/invite/', { eventId: this.event.id, emails: emails }).then(response => {
                    this.loading = false;
                    this.invPartVisible = false;
                    this.$message({
                        message: 'Participants invited to the event.',
                        type: 'success'
                    });

                }).catch((error) => {
                    this.loading = false;
                });
            },
            saveChanges() {
                this.$refs["eventEditForm"].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        var formData = new FormData();
                        if (this.file != null) {
                            formData.append("file", this.file.raw);
                            for (const [key, value] of Object.entries(this.eventEdit.imageCropData)) {
                                formData.append("imageCropData." + key, value);
                            }
                        }

                        for (const [key, value] of Object.entries(this.eventEdit)) {
                            if (key === "imageCropData")
                                continue;
                            formData.append(key, value);
                        }

                        axios.put('/api/events', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }).then(response => {
                            this.loading = false;
                            this.editMode = false;
                            this.event.imageSrc = response.data;
                            this.$message({
                                message: 'Changes saved to the event.',
                                type: 'success'
                            });
                        }).catch((error) => {
                            this.loading = false;
                        });
                    }
                });
            }
        }
    }
</script>

<style scoped>
    img {
        height: 300px;
        object-fit: cover;
    }

    .participants, .donatros {
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 20px;
        min-height: 370px;
    }

    .top-label {
        position: relative;
        top: 22px;
        background: #fff;
        left: 15px;
        font-weight: bold;
        padding: 0 5px;
    }

    .count {
        top: 25px;
        left: 15px;
    }

    .title {
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 20px;
        /*min-height: 250px;*/
        flex-direction: inherit;
    }

    /* Small devices (landscape phones, 544px and up) */
    @media (min-width: 370px) {
        h5 {
            font-size: 1.0rem;
        }

        h6 {
            font-size: .80rem;
            /*font-weight: bold;*/
        }
        /*1rem = 16px*/
    }

    /* Small devices (landscape phones, 544px and up) */
    @media (min-width: 544px) {
        h5 {
            font-size: 1.00rem;
        }

        h6 {
            font-size: .90rem;
        }
        /*1rem = 16px*/
    }

    /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
    @media (min-width: 768px) {
        h5 {
            font-size: 1.25rem;
        }

        h6 {
            font-size: .95rem;
        }
        /*1rem = 16px*/
    }

    /* Large devices (desktops, 992px and up) */
    /*@media (min-width: 992px) {
        h5 {
            font-size: 1.5rem;
        }

        h6 {
            font-size: 1.0rem;
        }*/
        /*1rem = 16px*/
    /*}*/

    /* Extra large devices (large desktops, 1200px and up) */
    /*@media (min-width: 1600px) {
        h5 {
            font-size: 2.0rem;
        }

        h6 {
            font-size: 1.25rem;
        }*/
        /*1rem = 16px*/
    /*}*/

</style>

