<template>
    <footer>
        <hr class="row col-12" style="color: #983222" />
        <div class="row" style="margin-left:unset; margin-right:unset; background-color: #FFFFFF; padding-right: unset; padding-left: unset; padding-left: 0; padding-right: 0; justify-content:center">
            <div class="row col-12 mt-2" style="justify-content:center">
                <div class="col-3" >                  
                </div>
                <div class="col-6" style="text-align:center" >                 
                    <a class="mr-4" href="https://facebook.com/coloradojackpopcorn" style="color: #983222"><i class="fab fa-facebook-f" style="font-size:24px"></i></a>
                    <a class="mr-4" href="https://twitter.com/cojackpopcorn" style="color: #983222"><i class="fab fa-twitter" style="font-size:24px"></i></a>
                    <a class="" href="https://www.instagram.com/coloradojackpopcorn" style="color: #983222"><i class="fab fa-instagram" style="font-size:24px"></i></a>
                </div>
                <div class="col-3">                    
                </div>                
            </div>
            <hr class="row col-12" style="color: #983222" />
            <div class="row col-12" style="justify-content:center">
                <router-link :to="{ name: 'TermsOfUse' }" class="nav-link" style="color: #983222;font-size:small">Terms and Conditions</router-link>
                <router-link :to="{ name: 'PrivacyPolicy' }" class="nav-link" style="color: #983222; font-size: small ">Privacy policy</router-link>
            </div>
        </div>
    </footer>
</template>


<script>
   
    export default {
        name: "Footer",
        data() {
            return {               
            }
        },
        methods: {           
        },
        computed: {                   
        },
        created() {           
        },
        mounted() {           
        }
    }
</script>

<style>
    /* header {
        background-color: #fff;
        z-index: 999;
    }*/

    footer {
        background-color: lightgray;
        /*position: fixed;*/ /* fixing the position takes it out of html flow - knows
                   nothing about where to locate itself except by browser
                   coordinates */
        left: 0; /* top left corner should start at leftmost spot */
        bottom: 0; /* top left corner should start at topmost spot */
        width: 100vw; /* take up the full browser width */
    }

    html {
        font-size: 14px;
    }
</style>
