<template>
    <div class="container checkout mt-5">
        <div class="row">
            <div class="col-12 col-sm-7 process">
                <div v-if="currentStep == 'delivery'">
                    <div class="h5 mb-4">
                        <span>CONTACT INFORMATION</span>
                    </div>
                    <div class="email-form">
                        <el-form :model="checkout" :rules="emailRules" ref="emailForm" :label-position="top" hide-required-asterisk="true" show-message="false" @submit.prevent>
                            <el-form-item label="EMAIL" prop="email">
                                <el-input type="text" v-model="checkout.email" v-focus v-fancylabel></el-input>
                                <el-checkbox v-model="checkout.newsUpdate">Keep me up to date on news and offers</el-checkbox>
                            </el-form-item>

                        </el-form>
                    </div>
                    <div class="h5 mb-4">
                        <span>DELIVERY METHOD</span>
                    </div>
                    <div class="delivery-option mb-4">
                        <div style="border:1px solid #DCDFE6;border-radius:5px;">
                            <el-radio-group v-model="checkout.deliveryMethod" class="w-100">
                                <div class="pl-2 pr-2 pt-4 pb-3">
                                    <el-radio label="ship"><i class="el-icon-ship"></i> Ship</el-radio>
                                </div>
                                <!--<div class="pl-2 pr-2 pt-4 pb-3 border-top justify-content-end">
                                    <el-radio label="pickup"><i class="el-icon-present"></i> Pick up</el-radio>
                                </div>-->
                            </el-radio-group>
                        </div>
                    </div>
                    <div v-if="checkout.deliveryMethod=='ship'">
                        <div class="h5 mb-4">
                            <span>ADDRESS</span>
                        </div>
                        <el-form :model="checkout.shippingAddress" :rules="addressRules" ref="addressForm" :label-position="top" hide-required-asterisk="true" show-message="false" @submit.prevent>
                            <div class="row">
                                <div class="col">
                                    <el-form-item label="FIRST NAME" prop="firstName">
                                        <el-input type="text" v-model="checkout.shippingAddress.firstName" v-fancylabel v-on:keyup.enter="continueShipping"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="col">
                                    <el-form-item label="LAST NAME" prop="lastName">
                                        <el-input type="text" v-model="checkout.shippingAddress.lastName" v-fancylabel v-on:keyup.enter="continueShipping"></el-input>
                                    </el-form-item>
                                </div>
                            </div>
                            <div>
                                <el-form-item label="COMPANY (optional)" prop="company">
                                    <el-input type="text" v-model="checkout.shippingAddress.company" autocomplete="off" v-fancylabel v-on:keyup.enter="continueShipping"></el-input>
                                </el-form-item>
                            </div>
                            <div>
                                <el-form-item label="ADDRESS" prop="addressLine1">
                                    <el-input type="text" v-model="checkout.shippingAddress.addressLine1" autocomplete="off" v-fancylabel v-on:keyup.enter="continueShipping"></el-input>
                                </el-form-item>
                            </div>
                            <div>
                                <el-form-item label="APARTMENT, SUITE, ETC. (optional)" prop="addressLine2">
                                    <el-input type="text" v-model="checkout.shippingAddress.addressLine2" autocomplete="off" v-fancylabel v-on:keyup.enter="continueShipping"></el-input>
                                </el-form-item>
                            </div>
                            <div>
                                <el-form-item label="CITY" prop="city">
                                    <el-input type="text" v-model="checkout.shippingAddress.city" autocomplete="off" v-fancylabel v-on:keyup.enter="continueShipping"></el-input>
                                </el-form-item>
                            </div>
                            
                            <div class="row">
                                <div class="h6 mb-3 ml-3">
                                    <span style="color: #983222">Please note: we only ship to the lower 48 states.</span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <el-form-item label="COUNTRY" prop="country">
                                        <el-select v-model="checkout.shippingAddress.country" v-fancylabel :disabled="true">
                                            <el-option key="USA"
                                                       label="USA"
                                                       value="USA"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="col">
                                    <el-form-item label="STATE" prop="state">

                                        <el-select filterable v-model="checkout.shippingAddress.state" v-fancylabel>
                                            <el-option v-for="item in states"
                                                       :key="item.id"
                                                       :label="item.name"
                                                       :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="col">
                                    <el-form-item label="ZIPCODE" prop="zipCode">
                                        <el-input type="text" v-model="checkout.shippingAddress.zipCode" autocomplete="off" v-fancylabel v-on:keyup.enter="continueShipping"></el-input>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <el-form-item label="PHONE (optional)" prop="phone">
                                        <el-input type="tel" v-model="checkout.shippingAddress.phone" autocomplete="off" v-fancylabel v-on:keyup.enter="continueShipping"></el-input>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="col-12 col-md-4 offset-md-1 d-block d-md-none mb-3 mt-3 pl-0 pr-0 border rounded">
                                <OrderReview v-if="checkout.deliveryMethod=='ship'" :shippingFee="shippingFee"></OrderReview>
                                <OrderReview v-else ></OrderReview>
                            </div>
                            <div>
                                <el-form-item>
                                    <el-button type="primary" plain @click="toCart">RETURN TO CART</el-button>
                                    <el-button type="danger" style="background-color: #983222" @click="continueShipping">CONTINUE</el-button>
                                </el-form-item>
                            </div>
                        </el-form>
                    </div>
                    <div v-else>
                        <div class="pickup-locations">
                            <div class="h6  mb-4">PICKUP LOCATIONS</div>
                            <div style="border:1px solid #DCDFE6;border-radius:5px;">
                                <div class="location p-2 mb-2" :class="{'border-bottom':index<pickupLocations.length-1}" v-for="(location, index) in pickupLocations" v-bind:key="location.id">
                                    <div class="row">
                                        <div class="col-8">
                                            <el-radio v-model="checkout.selectedPickupLocation" :label="location.id">{{location.name}}</el-radio>
                                        </div>
                                        <div class="col text-right ">
                                            {{location.note}}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-8" style="padding-left: 38px;">
                                            {{location.address}}
                                        </div>
                                        <div class="col-4 text-right">{{location.openHours}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 offset-md-1 d-block d-md-none mt-3 mb-3 pl-0 pr-0 border rounded">

                            <OrderReview v-if="checkout.deliveryMethod=='ship'" :shippingFee="shippingFee"></OrderReview>
                            <OrderReview v-else ></OrderReview>
                        </div>
                        <div class="mt-5">
                            <el-form-item>
                                <el-button type="primary" plain @click="toCart">RETURN TO CART</el-button>
                                <el-button type="danger" style="background-color: #983222" @click="continueBilling">CONTINUE</el-button>
                            </el-form-item>
                        </div>
                    </div>
                </div>
                <div v-if="currentStep == 'shippingMethod'">
                    <div class="contact rounded border pt-3 pb-3 mb-4">
                        <div class="row">
                            <div class="col-12 ml-1">CONTACT:</div>
                            <div class="col-9 ml-1">{{checkout.email}}</div>
                            <div class="col-2 text-right"><a @click="currentStep='delivery'" class="c-pointer">Change</a></div>
                        </div>
                        <hr />
                        <div class="row mt-3">
                            <div class="col-12 ml-1">SHIP TO:</div>
                            <div class="col-9 ml-1">{{shippingAddressString}}</div>
                            <div class="col-2 text-right"><a @click="currentStep='delivery'" class="c-pointer">Change</a></div>
                        </div>
                    </div>
                    <div class="h5 mb-4 mt-4">SHIPPING</div>
                    <div style="border:1px solid #DCDFE6;border-radius:5px;">
                        <div class="contact rounded border pl-2 pr-2 pt-4 pb-3">
                            <div class="row">
                                <div class="col-9">
                                    <el-radio>Shipping Total:</el-radio>
                                </div>
                                <div v-if="this.shippingFee>0" class="col-3">
                                    ${{parseFloat(this.shippingFee).toFixed(2)}}
                                </div>
                                <div v-else class="col ">
                                    ${{0.00}}
                                </div>
                            </div>                            
                        </div>
                    </div>
                    <div class="col-12 col-md-4 d-block d-md-none mb-3 mt-3 pl-0 pr-0 border rounded">
                        <OrderReview v-if="checkout.deliveryMethod=='ship'" :shippingFee="shippingFee"></OrderReview>
                        <OrderReview v-else ></OrderReview>
                    </div>
                    <div class="mt-5">
                        <el-form-item>
                            <el-button type="primary" plain @click="currentStep='delivery'" class="c-pointer">RETURN TO CONTACT INFO</el-button>
                            <el-button type="danger" style="background-color: #983222" @click="continueBilling">CONTINUE</el-button>
                        </el-form-item>
                    </div>
                </div>
                <div v-if="currentStep == 'billing'">
                    <div class="contact rounded border pt-3 pb-3 mb-4">
                        <div class="row">
                            <div class="col-12 ml-1">CONTACT:</div>
                            <div class="col-9 ml-1">{{checkout.email}}</div>
                            <div class="col-2 text-right"><a @click="currentStep='delivery'" class="c-pointer">Change</a></div>
                        </div>
                        <hr />
                        <template v-if="checkout.deliveryMethod=='ship'">
                            <div class="row mt-3">
                                <div class="col-12 ml-1">SHIP TO:</div>
                                <div class="col-9 ml-1">{{shippingAddressString}}</div>
                                <div class="col-2 text-right"><a @click="currentStep='delivery'" class="c-pointer">Change</a></div>
                            </div>
                            <hr />
                            <!--<div class="row mt-3">
                                <div class="col-2 ml-1">METHOD</div>
                                <div class="col-8">{{checkout.selectedShippingMethod.name}}.{{this.checkout.selectedShippingMethod.description}}</div>
                            </div>-->
                        </template>
                        <template v-else>
                            <div class="row mt-3">
                                <div class="col-2">METHOD</div>
                                <div class="col-8">Pick up in store . <span class="">{{pickupLocation.name}}</span></div>
                                <div class="col-2"><a class="c-pointer">Change</a></div>
                                <div class="offset-2 col-10 mt-2">{{pickupLocation.address}}</div>
                            </div>
                        </template>
                    </div>              
                    <div class="billing-address">
                        <div class=" mb-4">
                            <div class="h5">BILLING ADDRESS</div>
                            <div>
                                Enter the address that matches your card or payment method.
                            </div>
                        </div>
                        <div class="rounded border mb-4" v-if="checkout.deliveryMethod=='ship'">
                            <el-radio-group v-model="checkout.billingAddressAsShippingAddress" class="w-100">
                                <div class="pl-2 pr-2 pt-4 pb-3">
                                    <el-radio label="yes"><i class="el-icon-ship"></i> 	Same as shipping address</el-radio>
                                </div>
                                <div class="pl-2 pr-2 pt-4 pb-3 border-top justify-content-end">
                                    <el-radio label="no"><i class="el-icon-present"></i> Use a different billing address</el-radio>
                                </div>
                            </el-radio-group>
                        </div>
                        <div class="billing-address-form" v-if="checkout.deliveryMethod=='pickup' || checkout.billingAddressAsShippingAddress=='no'">
                            <el-form :model="checkout.billingAddress" :rules="addressRules" ref="billingAddressForm" :label-position="top" hide-required-asterisk="true" show-message="false" @submit.prevent>
                                <div class="row">
                                    <div class="col">
                                        <el-form-item label="FIRST NAME" prop="firstName">
                                            <el-input type="text" v-model="checkout.billingAddress.firstName" v-fancylabel v-on:keyup.enter="continueShipping"></el-input>
                                        </el-form-item>
                                    </div>
                                    <div class="col">
                                        <el-form-item label="LAST NAME" prop="lastName">
                                            <el-input type="text" v-model="checkout.billingAddress.lastName" v-fancylabel v-on:keyup.enter="continueShipping"></el-input>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div>
                                    <el-form-item label="COMPANY (optional)" prop="company">
                                        <el-input type="text" v-model="checkout.billingAddress.company" autocomplete="off" v-fancylabel v-on:keyup.enter="continueShipping"></el-input>
                                    </el-form-item>
                                </div>
                                <div>
                                    <el-form-item label="ADDRESS" prop="addressLine1">
                                        <el-input type="text" v-model="checkout.billingAddress.addressLine1" autocomplete="off" v-fancylabel v-on:keyup.enter="continueShipping"></el-input>
                                    </el-form-item>
                                </div>
                                <div>
                                    <el-form-item label="APARTMENT, SUITE, ETC. (optional)" prop="addressLine2">
                                        <el-input type="text" v-model="checkout.billingAddress.addressLine2" autocomplete="off" v-fancylabel v-on:keyup.enter="continueShipping"></el-input>
                                    </el-form-item>
                                </div>
                                <div>
                                    <el-form-item label="CITY" prop="city">
                                        <el-input type="text" v-model="checkout.billingAddress.city" autocomplete="off" v-fancylabel v-on:keyup.enter="continueShipping"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <el-form-item label="COUNTRY" prop="country">
                                            <el-select v-model="checkout.billingAddress.country" v-fancylabel :disabled="true">
                                                <el-option key="USA"
                                                           label="USA"
                                                           value="USA"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                    <div class="col">
                                        <el-form-item label="STATE" prop="state">
                                            <el-select filterable v-model="checkout.billingAddress.state" v-fancylabel>
                                                <el-option v-for="item in states"
                                                           :key="item.id"
                                                           :label="item.name"
                                                           :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                    <div class="col">
                                        <el-form-item label="ZIPCODE" prop="zipCode">
                                            <el-input type="text" v-model="checkout.billingAddress.zipCode" autocomplete="off" v-fancylabel v-on:keyup.enter="continueShipping"></el-input>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <el-form-item label="PHONE (optional)" prop="phone">
                                            <el-input type="tel" v-model="checkout.billingAddress.phone" autocomplete="off" v-fancylabel v-on:keyup.enter="continueShipping"></el-input>
                                        </el-form-item>
                                    </div>
                                </div>
                            </el-form>
                        </div>
                    </div>
                    <div>
                        <el-form-item>
                            <el-button type="primary" plain @click="currentStep='shippingMethod'">BACK TO SHIPPING</el-button>
                            <el-button type="danger" style="background-color: #983222" @click="continuePayment" :loading="loading">CONTINUE</el-button>
                        </el-form-item>
                    </div>
                </div>
                <div v-if="currentStep == 'payment'">
                    <el-alert v-show="errorMessage.length>0" :title="errorMessage"
                              type="error"
                              effect="dark">
                    </el-alert>
                    <div class="contact rounded border p-3 mb-4">
                        <div class="row">
                            <div class="col-12 ml-1">CONTACT:</div>
                            <div class="col-9 ml-1">{{checkout.email}}</div>
                            <div class="col-2 text-right"><a @click="currentStep='delivery'" class="c-pointer">Change</a></div>
                        </div>
                        <hr />
                        <template v-if="checkout.deliveryMethod=='ship'">
                            <div class="row mt-3">
                                <div class="col-12 ml-1">SHIP TO:</div>
                                <div class="col-9 ml-1">{{shippingAddressString}}</div>
                                <div class="col-2 text-right"><a @click="currentStep='delivery'" class="c-pointer">Change</a></div>
                            </div>
                            <hr />
                            <!--<div class="row mt-3">
                                <div class="col-12 ml-1">METHOD:</div>
                                <div class="col-8 ml-1">{{checkout.selectedShippingMethod.name}}.{{checkout.selectedShippingMethod.description}}</div>
                            </div>-->
                        </template>
                        <template v-else>
                            <div class="row mt-3">
                                <div class="col-2">METHOD</div>
                                <div class="col-8">Pick up in store . <span class="">{{pickupLocation.name}}</span></div>
                                <div class="col-2"><a class="c-pointer">Change</a></div>
                                <div class="offset-2 col-10 mt-2">{{pickupLocation.address}}</div>
                            </div>
                        </template>
                    </div>
                    <payment-form v-on:payment-success="paymentSuccess" v-on:card-failure="invalidCard" :total="cart.total" :client-secret="clientSecret" ref="paymentForm"></payment-form>
                    <div>
                        <el-form-item>
                            <el-button type="primary" plain @click="currentStep='shippingMethod'">BACK TO SHIPPING</el-button>
                            <el-button type="primary" v-if="completeOrderBtn" @click="completeOrder" :loading="loading" :disabled="completeOrderBtn">COMPLETE ORDER</el-button>
                            <el-button type="primary" v-else style="background-color: #983222" @click="completeOrder" :loading="loading" :disabled="completeOrderBtn">COMPLETE ORDER</el-button>
                        </el-form-item>
                    </div>
                </div>
            </div>
            <div class="col-sm-4 offset-md-1 d-none d-md-block">
                <OrderReview v-if="checkout.deliveryMethod=='ship'" :shippingFee="shippingFee" ></OrderReview>
                <OrderReview v-else ></OrderReview>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from "axios"
    import OrderReview from "./OrderReview"
    import PaymentForm from "./PaymentForm"
    import router from "../router";
    import store from "@/store"
    export default {
        name: 'Checkout',
        components: { OrderReview, PaymentForm },
        data() {
            return {
                errorMessage: '',
                orderCode: '',
                cart: {},
                pickupLocations: [],
                shippingMethods: [],
                currentStep: 'delivery',
                clientSecret: '',
                completeOrderBtn: true,
                order: {},
                checkout: {
                    email: '',
                    newsUpdate: false,
                    deliveryMethod: 'ship',
                    selectedPickupLocation: '',
                    selectedShippingMethod: '',
                    billingAddressAsShippingAddress: 'yes',
                    shippingFee: 0,
                    shippingAddress: {
                        country: 'USA',
                        firstName: '',
                        lastName: '',
                        company: '',
                        addressLine1: '',
                        addressLine2: '',
                        city: '',
                        state: '',
                        zipCode: '',
                        phone: ''
                    },
                    billingAddress: {
                        country: 'USA',
                        firstName: '',
                        lastName: '',
                        company: '',
                        addressLine1: '',
                        addressLine2: '',
                        city: '',
                        state: '',
                        zipCode: '',
                        phone: ''
                    },
                    payment: {
                        method:'card',
                        token: null,
                        cardNumber: null,
                        cardExpiry: null,
                        cardCvc: null,
                    }
                },
                loading: false,
                paymentError: '',
                addressRules: {
                    firstName: [
                        { required: true, message: 'Enter a first name', trigger: 'blur' }
                    ],
                    lastName: [
                        { required: true, message: 'Enter a last name', trigger: 'blur' }
                    ],
                    addressLine1: [
                        { required: true, message: 'Enter an address', trigger: 'blur' }
                    ],
                    city: [
                        { required: true, message: 'Enter a city', trigger: 'blur' }
                    ],
                    state: [
                        { required: true, message: 'Select a state', trigger: 'blur' }
                    ],
                    zipCode: [
                        { required: true, message: 'Enter a zip code', trigger: 'blur' }
                    ],
                    country: [
                        { required: true, message: 'Select a country', trigger: 'blur' }
                    ]
                },
                emailRules: {
                    email: [
                        { required: true, type: 'email', message: 'Invalid email address', trigger: 'blur' }
                    ]
                },
                states: [
                    { name: "Alabama", id: "AL" },
                    { name: "Arizona", id: "AZ" },
                    { name: "Arkansas", id: "AR" },
                    { name: "California", id: "CA" },
                    { name: "Colorado", id: "CO" },
                    { name: "Connecticut", id: "CT" },
                    { name: "Delaware", id: "DE" },
                    { name: "District of Columbia", id: "DC" },
                    { name: "Florida", id: "FL" },
                    { name: "Georgia", id: "GA" },
                    { name: "Idaho", id: "ID" },
                    { name: "Illinois", id: "IL" },
                    { name: "Indiana", id: "IN" },
                    { name: "Iowa", id: "IA" },
                    { name: "Kansas", id: "KS" },
                    { name: "Kentucky", id: "KY" },
                    { name: "Louisiana", id: "LA" },
                    { name: "Maine", id: "ME" },
                    { name: "Maryland", id: "MD" },
                    { name: "Massachusetts", id: "MA" },
                    { name: "Michigan", id: "MI" },
                    { name: "Minnesota", id: "MN" },
                    { name: "Mississippi", id: "MS" },
                    { name: "Missouri", id: "MO" },
                    { name: "Montana", id: "MT" },
                    { name: "Nebraska", id: "NE" },
                    { name: "Nevada", id: "NV" },
                    { name: "New Hampshire", id: "NH" },
                    { name: "New Jersey", id: "NJ" },
                    { name: "New Mexico", id: "NM" },
                    { name: "New York", id: "NY" },
                    { name: "North Carolina", id: "NC" },
                    { name: "North Dakota", id: "ND" },
                    { name: "Ohio", id: "OH" },
                    { name: "Oklahoma", id: "OK" },
                    { name: "Oregon", id: "OR" },
                    { name: "Pennsylvania", id: "PA" },
                    { name: "Rhode Island", id: "RI" },
                    { name: "South Carolina", id: "SC" },
                    { name: "South Dakota", id: "SD" },
                    { name: "Tennessee", id: "TN" },
                    { name: "Texas", id: "TX" },
                    { name: "Utah", id: "UT" },
                    { name: "Vermont", id: "VT" },
                    { name: "Virginia", id: "VA" },
                    { name: "Washington", id: "WA" },
                    { name: "West Virginia", id: "WV" },
                    { name: "Wisconsin", id: "WI" },
                    { name: "Wyoming", id: "WY" }
                ]
            }
        },
        methods: {
            continueShipping() {
                this.$refs["addressForm"].validate((valid) => {
                    if (valid) {
                        this.currentStep = 'shippingMethod';
                    }
                });
            },
            continueBilling() {
                this.currentStep = 'billing';
            },
            continuePayment() {
                if (this.$refs["billingAddressForm"] == null) {
                    this.saveOrder();
                    this.currentStep = 'payment'                }
                else {
                    this.$refs["billingAddressForm"].validate((valid) => {
                        if (valid) {
                            this.saveOrder();
                            this.currentStep = 'payment'
                        }
                    });
                }
            },
            saveOrder() {
                //save order
                //proceed with the checkout
                if (this.checkout.deliveryMethod == "pickup")
                    this.checkout.billingAddressAsShippingAddress = 'no';
                this.checkout.shippingFee = this.shippingFee;
                this.checkout.total = store.getters.cart.total;
                let order = {
                    total: store.getters.cart.total,
                    shippingFee: this.checkout.shippingFee,
                    contactEmail: this.checkout.email,
                    newsUpdate: this.checkout.newsUpdate,
                    deliveryMethod: this.checkout.deliveryMethod,
                    pickupLocationId: this.checkout.selectedPickupLocation,
                    shippingMethodId: this.checkout.selectedShippingMethod.id,
                    baddressAsSAddress: this.checkout.billingAddressAsShippingAddress == 'yes',
                    shippingAddress: this.checkout.shippingAddress,
                    billingAddress: this.checkout.billingAddressAsShippingAddress == 'yes' ? this.checkout.shippingAddress : this.checkout.billingAddress,
                    existingCode: this.orderCode
                }
                this.cart.total = parseFloat(order.total) + parseFloat(order.shippingFee);

                if (store.getters.cart != undefined) {
                    order.products = [];
                    store.getters.cart.products.forEach(p => {
                        let product = { productId: p.id, count: p.count, storeCode: p.storeCode, bags: [] };
                        order.products.push(product);
                        p.bags.forEach(b => {
                            product.bags.push({ bagId: b.id, count: b.count });
                        });
                    });
                    //let jstring = JSON.stringify(order);
                    //alert(jstring);
                    //post order
                    this.loading = true;
                    axios.post('/api/orders', order).then(response => {
                        this.loading = false;
                        if (response.data.status === 'success') {
                            this.orderCode = response.data.code;
                            this.orderNumber = response.data.number;
                            this.clientSecret = response.data.client_secret;
                            this.currentStep = 'payment';
                        }
                        else {
                            this.errorMessage = response.data.code;
                            //alert(response.data.code);
                        }
                    }).catch((error) => {
                        this.loading = false;
                    });
                }
            },            
            toCart() {
                router.push('/cart');
            },
            completeOrder() {
                //call this to continue payment with credit cards
                this.loading = true;
                this.$refs.paymentForm.completePayment();
            },
            invalidCard(status, message) {
                if (status) {
                    this.completeOrderBtn = true;
                }
                else {
                    this.completeOrderBtn = false;
                }                
            },
            paymentFail(failError) {
                this.loading = false;
                this.paymentError = failError;
               // alert(failError);
            },
            //will be called from payment form when user payes with Google/Apple pay
            paymentSuccess(status, paymentMethod) {
                if (status) {
                    this.finishOrder(paymentMethod);
                }
                else {
                    this.paymentFail("test");
                }               
            },
            finishOrder(paymentMethod) {
                let order = {};
                order.deliveryMethod = this.checkout.deliveryMethod;
                order.shippingMethodName = this.shippingMethodName;
                order.pickupLocationAddress = this.pickupLocationAddress;
                order.shippingAddressString = this.shippingAddressString;
                order.billingAddressString = this.billingAddressString;
                order.total = parseFloat(this.checkout.total) + parseFloat(this.checkout.shippingFee);
                order.number = this.orderNumber;
                order.email = this.checkout.email;
                store.dispatch('clearCart');
                store.dispatch('setCompletedOrder', order);
            }
        },
        computed: {
            shippingMethodName() {
                let product = store.getters.cart.products.filter(p => p.freeShipping === false);
                var method = this.shippingMethods.filter(f => f.id === product.shippingMethodID);
                if (method.length > 0)
                    return method[0].name;
                return "";
            },
            pickupLocationAddress() {
                var location = this.pickupLocations.filter(f => f.id === this.checkout.selectedPickupLocation);
                if (location.length > 0)
                    return location[0].address;
                return "";
            },
            shippingFee() {
                let shippingFee = 0;
                let product = store.getters.cart.products.filter(p => p.freeShipping === false);

                product.forEach((p) => {
                    shippingFee += (p.count * parseFloat(p.shippingCost));
                });
                return shippingFee.toFixed(2);               
            },

            shippingAddressString() {
                let address = '';
                if (this.checkout.shippingAddress.company != '')
                    address += this.checkout.shippingAddress.company + ', ';
                address += this.checkout.shippingAddress.addressLine1 + ', ';
                if (this.checkout.shippingAddress.addressLine2 != '')
                    address += this.checkout.shippingAddress.addressLine2 + ', ';
                address += (this.checkout.shippingAddress.city + ' ' + this.checkout.shippingAddress.state + ' ' + this.checkout.shippingAddress.zipCode + ', ' + this.checkout.shippingAddress.country);
                return address;
            },
            billingAddressString() {
                if (this.checkout.billingAddressAsShippingAddress == 'yes')
                    return this.shippingAddressString;
                let address = '';
                if (this.checkout.billingAddress.company != '')
                    address += this.checkout.billingAddress.company + ', ';
                address += this.checkout.billingAddress.addressLine1 + ', ';
                if (this.checkout.billingAddress.addressLine2 != '')
                    address += this.checkout.billingAddress.addressLine2 + ', ';
                address += (this.checkout.billingAddress.city + ' ' + this.checkout.billingAddress.state + ' ' + this.checkout.billingAddress.zipCode + ', ' + this.checkout.billingAddress.country);
                return address;
            },
            shippingMethod() {
                return this.shippingMethods.filter(item => item.id == this.checkout.selectedShippingMethod);
            },
            pickupLocation() {
                return this.pickupLocations.filter(item => item.id == this.checkout.selectedPickupLocation)[0];
            }
        },
        mounted() {
           
        },
        created() {
            //loca pickup locations
            axios.get('/api/lookup/pickuplocations').then(response => {
                this.pickupLocations = response.data;
                this.checkout.selectedPickupLocation = this.pickupLocations[0].id;
            }).catch((error) => {
            });
            //load shipping methods
            axios.get('/api/lookup/shippingmethods').then(response => {
                this.shippingMethods = response.data;
                let product = store.getters.cart.products.filter(p => p.freeShipping === false);
                product.forEach((p) => {
                    let shippingmethod = this.shippingMethods.filter(item => item.id == p.ShippingMethodID);
                    this.checkout.selectedShippingMethod = shippingmethod[0];
                });
                
            }).catch((error) => {
            });
            //get addresses based on user ip
            //the only we can get is region
            axios.get('https://ipinfo.io?token=15c2bfa58b26f4').then(response => {
                //this.checkout.shippingAddress.city = response.data.city;
                this.states.forEach((s) => {
                    if (s.name == response.data.region) {
                        this.checkout.shippingAddress.state = s.id;
                        this.checkout.billingAddress.state = s.id;
                    }
                });

                //this.checkout.shippingAddress.zipCode = response.data.postal;
                //this.checkout.billingAddress.city = response.data.city;
                //this.checkout.billingAddress.state = response.data.region;
                //this.checkout.billingAddress.zipCode = response.data.postal;
            }).catch((error) => {
            });
        }
    }
</script>
<style>
    .checkout .email-form .el-form-item__error {
        top: 62px;
    }
    .card-types {
        float: right;
        margin-top: -5px;
        margin-right: 10px;
    }
</style>
