<template>
    <div class="timer">
        <div style="padding-bottom:5px" :class="{'text-danger':progressWidth >= 90}">{{days}} <sup>{{daysLabel}}</sup> - {{hours}} <sup>{{hoursLabel}}</sup> and {{minutes}} <sup>{{minutesLabel}}</sup></div>
        <el-progress :stroke-width="7" :percentage="elapsedMinutesPercent.toFixed(2)" :status="status">
            <span></span>
        </el-progress>

    </div>
   
</template>

<script>
import { mapGetters } from "vuex";

    export default {
        name: "EventTimer",
        props: {
            startDate: String,
            endDate: String
        },
        data() {
            return {
                days: '',
                hours: '',
                minutes: '',
                seconds: '',
                totalMinutes: 0,
                elapsedMinutes:0,
                progressWidth: 0,
                elapsedMinutesPercent: 0,
                status:''
            }
        },
        methods: {
            countDownTimer() {
                function pad(num, size) {
                    var s = "0000000000000000000000" + num;
                    return s.substr(s.length - size);
                }
                //calculate elapsed minutes
                var countDownDate = new Date(this.startDate).getTime();
                // Get today's date and time
                var now = new Date().getTime();

                var distance = now - countDownDate;

                this.elapsedMinutes = Math.floor(distance / (1000 * 60));
                distance = new Date(this.endDate).getTime() - new Date(this.startDate).getTime()
                this.totalMinutes = Math.floor(distance / (1000 * 60));
                this.elapsedMinutesPercent = (this.elapsedMinutes / this.totalMinutes) * 100;

                if (this.elapsedMinutesPercent > 50)
                    this.status = 'warning';
                else if (this.elapsedMinutesPercent > 90)
                    this.status = 'exception';

                countDownDate = new Date(this.endDate).getTime();
                let seconds = 0;
                // Update the count down every 1 second
                var x = setInterval(() => {
                    seconds++
                    if (seconds == 60) {
                        seconds = 0;
                        this.elapsedMinutes++;
                        this.elapsedMinutesPercent = (this.elapsedMinutes / this.totalMinutes) * 100;
                        if (this.elapsedMinutesPercent > 50)
                            this.status = 'warning';
                        else if (this.elapsedMinutesPercent > 90)
                            this.status = 'exception';
                    }
                    // Get today's date and time
                    now = new Date().getTime();
                    // Find the distance between now and the count down date
                    distance = countDownDate - now;               
                    // Time calculations for days, hours, minutes and seconds
                    this.days =pad(Math.floor(distance / (1000 * 60 * 60 * 24)),2);
                    this.hours = pad(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)), 2);
                    this.minutes = pad(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)), 2);
                    this.seconds = pad(Math.floor((distance % (1000 * 60)) / 1000), 2);

                    // If the count down is finished, write some text
                    if (distance < 0) {
                        clearInterval(x);

                    }
                }, 1000);
            }
        },
        created() {
            this.countDownTimer();
        },
        computed: {
            ...mapGetters(["runningEvent"],),
            daysLabel() {
                return parseInt(this.days) > 1 ? "Days" : "Day";
            },
            hoursLabel() {
                return parseInt(this.hours) > 1 ? "Hours" : "Hour";
            },
            minutesLabel() {
                return parseInt(this.minutes) > 1 ? "Minutes" : "Minute";
            }
           
        }
    }
</script>

<style scoped>
    .progress {
        height: 10px;
        width: 115px;
        margin-top:10px;
    }
   .timer{
        width:100%;
    }
</style>