<template>

    <div class="row center col-md-12 justify-content-center mb-1" style="padding-right:unset; padding-left:unset">
        <!-- <img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/popcorn3200x800.webp" class="col-md-12" style="aspect-ratio:16/4; padding-left:0; padding-right:0" />-->
        <img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/BagsWood.webp" class="col-md-12 img-fluid" style="padding-left:0; padding-right:0" />
    </div>

   
    <div class="container-fluid center mb-5" style="background-color: white;">
        <!--<div class="row center col-md-11 justify-content-center mb-5">
            <img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/ketelPopcorn.webp" class="img-fluid col-md-7" style="padding-left:0; padding-right:0" />
            <div class="col-md-4" style="color: white; background-color: #983222">
                <H1 class="mt-3" style="color: white; ">LEGENDARY</H1>
                <H1 class="mt-1" style="color: white;">POPCORN.</H1>
                <H1 class="mt-1" style="color: white;">LEGENDARY</H1>
                <H1 class="mt-1" style="color: white;">FUNDRAISER.</H1>
                <h6 class="mt-3 mb-5 ml-5 mr-5" style="color: white;">Everyone loves popcorn which makes it easy to sell and a quick way to reach your fundraising goals.</h6>
                <div class="mb-2" style="text-align:center">
                    <el-button type="danger" style="background-color: white; color: #983222; width: 60%; padding-bottom: 20px" size="large" @click="toNewEvent">Start Today!</el-button>
                </div>
            </div>
        </div>-->

        <div class="row justify-content-center">
            <div class="col-md-11">
                <H1 class="mt-3" style="color: #983222">Our Story</H1>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-md-6 mb-5">
                <H6 class="mt-3" style="">Colorado Jack is a company founded on the values and principles of giving back. When owners Brian and Kim Engstrom searched for employment for their son, Cullen, whom at a young age was diagnosed with disruptive behavior disorder and autism, they quickly realized how difficult it can be. So as a way for them to help pay it forward, they created Colorado Jack Giveback; a fundraising program that provides resources, support, and job opportunities for individuals with cognitive and physical disabilities.  </H6>
            </div>
        </div>

        <hr class="col-md-12 mb-5" style="color: #983222; height: 20px; background-color: #983222;" />

        <div class="row center col-md-10 justify-content-center mt-lg-5 mb-5">
            <div class="col-md-5">
                <H1 class="center" style="color: #983222">SAY HELLO</H1>
                <p class="mt-3 font-weight-bold" style="color: #983222;">EMAIL ADDRESS</p>
                <p class="mt-2"><a href="mailto:info@coloradojackgiveback.com">info@coloradojackgiveback.com</a></p>               
            </div>
            <div class="col-md-5 ">
                <div class="row justify-content-center mt-3 ">
                    <img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/normalpopcorn.png" class="img-fluid mb-3" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import router from "../router";
    import { mapGetters } from "vuex"

export default {
        name: 'About',
        methods: {
            toNewEvent() {
                router.push("/Signup");
            },
            toLogin() {
                router.push("/login");
            }
        },
        computed: {
            ...mapGetters([
                'isAuthenticated', 'cart', 'currentUserDetail'
            ])
        },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    /* page content */
    h1, h2, h3, h4, h5 {
        /*font-family: Trebuchet MS;*/
        font-weight: bold;
        text-align: center;
    }
    h6 {
        /*font-family: Trebuchet MS;*/
        text-align: center;
    }


    .res-card {
        background-color: white;
    }

    .multi-bg-example {
        width: 100%;
        height: 800px;
        background-image: url("https://coloradojackgivebacksa.blob.core.windows.net/publicwebsiteimages/5 Flavors with corn cobs.png");
        background-repeat: no-repeat;
        background-position: bottom right, left, right;
    }

    .jumbotron-welcome {
        background-image: url("https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/Kid.jpg");
        background-size: cover;
        color: white;
    }

    /* Small devices (landscape phones, 544px and up) */
    @media (min-width: 380px) {
        p {
            font-size: 1.0rem;
        }
        h1 {
            font-size: 1.5rem;
        }
        h2 {
            font-size: 1.0rem;
        }
        h5 {
            font-size: 1.0rem;
        }
        h6 {
            font-size: 1.0rem;
        }
        /*1rem = 16px*/
    }

    /* Small devices (landscape phones, 544px and up) */
    @media (min-width: 544px) {
        p {
            font-size: 1.0rem;
        }
        h1 {
            font-size: 1.5rem;
        }
        h2 {
            font-size: 1.25rem;
        }
        h5 {
            font-size: 1.00rem;
        }
        h6 {
            font-size: 1.0rem;
        }
        /*1rem = 16px*/
    }

    /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
    @media (min-width: 768px) {
        p {
            font-size: 1.0rem;
        }
        h1 {
            font-size: 2.0rem;
        }
        h2 {
            font-size: 1.75rem;
        }
        h5 {
            font-size: 1.25rem;
        }
        h6 {
            font-size: 1.0rem;
        }
        /*1rem = 16px*/
    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {
        p {
            font-size: 1.25rem;
        }
        h1 {
            font-size: 2.5rem;
        }
        h2 {
            font-size: 2.0rem;
        }
        h5 {
            font-size: 1.5rem;
        }
        h6 {
            font-size: 1.25rem;
        }
        /*1rem = 16px*/
    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {
        p {
            font-size: 1.50rem;
        }
        h1 {
            font-size: 3.0rem;
        }
        h2 {
            font-size: 2.5rem;
        }
        h5 {
            font-size: 2.0rem;
        }
        h6 {
            font-size: 1.60rem;
        }
        /*1rem = 16px*/
    }

    .video-container {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px;
        height: 0;
        overflow: hidden;
    }

    .video-container iframe, .video-container object, .video-container embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
  

</style>
