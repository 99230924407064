<template>
    <div class="container" :loading="loading">
        <div class="row no-gutters center" style="justify-content:center">
            <div class="h2 font-weight-bold pt-2 pb-2 p-sm-0 pb-sm-3 elipssis center">
                <a style="justify-content:center" :href="event.url">{{eventEdit.title}}</a>
            </div>
        </div>       
        <div class="row no-gutters">
            <div class="col image-cover">
                <el-image :src="event.imageSrc">
                    <template #error>
                        <div class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </template>
                </el-image>                
            </div>
        </div>   
        <div class="row" >
            <div class="col-12" style="justify-content:center">{{eventEdit.description}}</div>
        </div>
        <hr />
        <div class="row">
            <div class="col-12">
                <label class="top-label">Fundraiser Information</label>
                <div class="title">
                    <div style="overflow: hidden; ">
                        <div class="row">
                            <div class="col-4 font-weight-bold"><h6 class="font-weight-bold">Fundraiser Title:</h6></div>
                            <div class="col-8 text-left"><h6>{{eventEdit.title}}</h6></div>
                        </div>
                        <div class="row">
                            <div class="col-4 font-weight-bold"><h6 class="font-weight-bold">Fundraiser Description:</h6></div>
                            <div class="col-8 text-left"><h6>{{eventEdit.description}}</h6></div>
                        </div>
                        <!--<div class="row">
                            <div class="col-4 font-weight-bold"><h6 class="font-weight-bold">Fundraiser Code:</h6></div>
                            <span class="col-8 text-left" id="eventCode" :data-copy="event.url"><h6>{{event.code}}</h6></span>
                        </div>-->
                        <div class="row">
                            <div class="col-4 font-weight-bold"><h6 class="font-weight-bold">Fundraiser Organizer Email:</h6></div>
                            <span class="col-8 text-left" ><h6>{{profile.email}}</h6></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-6">
                <label class="top-label">Fundraiser Statistics</label>
                <div class="title">
                    <div style="overflow: hidden; ">
                        <div class="row">
                            <div class="col-4"><h6 class="font-weight-bold">START DATE:</h6></div>
                            <div class="col-8"><h6>{{new Date(event.startDate).toLocaleDateString("en-US",{  year: 'numeric', month: 'long', day: 'numeric' })}}</h6></div>
                        </div>
                        <div class="row">
                            <div class="col-4"><h6 class="font-weight-bold">END DATE:</h6></div>
                            <div class="col-8"><h6>{{new Date(event.endDate).toLocaleDateString("en-US",{  year: 'numeric', month: 'long', day: 'numeric' })}}</h6></div>
                        </div>
                        <hr />
                        <div class="statistics">
                            <div class="row">
                                <div class="col-4"><h6 class="font-weight-bold">Fundraiser Target:</h6></div>
                                <div class="col-8 text-left"><h6>${{event.targetAmount}}</h6></div>
                            </div>
                            <div class="row">
                                <div class="col-4"><h6 class="font-weight-bold">Total Sales:</h6></div>
                                <div class="col-8 text-left"><h6>${{event.allStoresSubTotal}}</h6></div>
                            </div>
                            <!--<div class="row">
                        <div class="col-4"><h6 class="font-weight-bold">Orgainizer Store Total:</h6></div>
                        <div class="col-8 text-left"><h6>${{event.organizerStoreTotal}}</h6></div>
                    </div>
                    <div class="row">
                        <div class="col-4"><h6 class="font-weight-bold">Fundraiser Subtotal (total-fees):</h6></div>
                        <div class="col-8 text-left"><h6>${{event.organizerStoreSubTotal}}</h6></div>
                    </div>
                    <div class="row">
                        <div class="col-4"><h6 class="font-weight-bold">Elgible Payout Amount:</h6></div>
                        <div class="col-8 text-left"><h6>${{Math.round((event.organizerStoreSubTotal * 100) / 100) / 2}}</h6></div>
                    </div>-->
                            <!--<div class="row">
                        <div class="col-4"><h6>Total Sales:</h6></div>
                        <div class="col-8 text-left"><h6>${{calculatedTotals.eventSubtotal}}</h6></div>
                    </div>-->
                            <div class="row">
                                <div class="col-4"><h6>Total Earned:</h6></div>
                                <div class="col-8 text-left">
                                    <el-button style="color: #983222;padding-top:0px; padding-bottom:0px;" class="ml-0 mt-0 mb-0" type="text" size="small" @click="eventBreakDown=true" title="Click to view fundraiser dollar breakdown"><h6>${{Math.round((event.allStoresSubTotal * 100) / 100) / 2}}</h6></el-button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4"><h6 class="font-weight-bold">Number of Participants:</h6></div>
                                <div class="col-8 text-left"><h6>{{event.participantsCount}}</h6></div>
                            </div>
                            <div class="row">
                                <div class="col-4"><h6 class="font-weight-bold">Number of Donations:</h6></div>
                                <div class="col-8 text-left"><h6>{{event.donationsCount}}</h6></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <el-dialog v-model="showACH" :width="dialogWidth" :close-on-click-modal="false" :top="dialogTop">
                <el-alert v-show="errorMessage.length>0" :title="errorMessage"
                          type="error"
                          effect="dark">
                </el-alert>
                <div class="col-12" v-if="this.event.status=='Ended'&&this.event.organizerStoreSubTotal>0">
                    <label class="top-label">Fundraiser Payout Information - ACH Setup</label>
                    <div class="title">
                        <div style="overflow: hidden; ">
                            <el-form :model="profile" :rules="profileRules" ref="profileForm" :label-position="top" hide-required-asterisk="true" show-message="false" @submit.prevent>
                                <section class="form-title">
                                    <h6 style="word-break:keep-all;">Please verify your Bank Details. </h6>
                                    <h6 style="word-break:keep-all;"> We will deposit two micro-deposits into your bank account.</h6>
                                </section>
                                <section>
                                    <div class="font-weight-bold">
                                        Email:
                                    </div>
                                    <div class="mt-2" style="color: #983222">
                                        {{profile.email}}
                                    </div>
                                </section>
                                <hr />
                                <el-form-item label="First Name" prop="firstName">
                                    <el-input v-model="profile.firstName" v-fancylabel></el-input>
                                </el-form-item>
                                <el-form-item label="Last Name" prop="lastName">
                                    <el-input v-model="profile.lastName" v-fancylabel></el-input>
                                </el-form-item>
                                <el-form-item label="Account Name" prop="accountName" title="This can be anything">
                                    <el-input v-model="profile.accountName" v-fancylabel></el-input>
                                </el-form-item>
                                <el-form-item label="Account Type" prop="accountType">
                                    <el-select v-model="profile.accountType" v-fancylabel>
                                        <el-option v-for="item in accounttypes"
                                                   :key="item.id"
                                                   :label="item.name"
                                                   :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="Routing Number" prop="routingNumber">
                                    <el-input v-model="profile.routingNumber" v-fancylabel></el-input>
                                </el-form-item>
                                <el-form-item label="Account Number" prop="accountNumber">
                                    <el-input v-model="profile.accountNumber" v-fancylabel></el-input>
                                </el-form-item>
                                <div>
                                    <el-form-item>
                                        <el-button size="small" type="primary" @click="submitChanges" :loading="loading" class="float-left">SUBMIT CHANGES</el-button>
                                        <el-button size="small" type="primary" plain @click="showACH=false" class="float-left">CANCEL</el-button>
                                    </el-form-item>
                                </div>
                                <section v-if="profile.fundingSrcLinkStatus!=null">
                                    <div class="font-weight-bold">
                                        Bank Link Status:
                                    </div>
                                    <div class="mt-2" style="color: #983222">
                                        {{profile.fundingSrcLinkStatus}}
                                    </div>
                                </section>
                                <section v-if="profile.microDepositsStatus!=null">
                                    <div class="font-weight-bold">
                                        Micro-Deposits Status:
                                    </div>
                                    <div class="mt-2" style="color: #983222">
                                        {{profile.microDepositsStatus}}
                                    </div>
                                </section>
                            </el-form>
                        </div>
                    </div>
                </div>
            </el-dialog>

            <el-dialog title="TOTAL PROFITS" style="font-weight:bold" :width="dialogWidth" :top="dialogTop" v-model="eventBreakDown" lock-scroll="false">
                <div class="">
                    <el-form :model="calculatedTotals" show-message="false" @submit.prevent>
                        <!--<div class="row no-gutters pb-2">
                <div class="col-12 text-break">-->
                        <!--<h6>Total Dollars Earned for Event:</h6>-->
                        <!--</div>
                </div>-->
                        <div class="row no-gutters pb-2 h5">
                            <div class="col-12">
                                <div class="word-line-break mt-3"><span>BREAKDOWN</span></div>
                                <div class="row">
                                    <div class="col-9"><h6>Total Sales</h6></div>
                                    <div class="col-3 text-left"><h6>${{parseFloat(event.allStoresSubTotal).toFixed(2)}}</h6></div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-9"><h6>Total Earned Profits(50% of Fundraiser Subtotal):</h6></div>
                                    <div class="col-3 text-left"><h6 style="color: green">${{parseFloat(event.allStoresSubTotal / 2).toFixed(2)}}</h6></div>
                                </div>
                                <div class="word-line-break mt-3"><span>SALES</span></div>
                                <div class="donators">
                                    <el-table :data="donatorsOrders" style="width:100%" height="350">
                                        <el-table-column prop="orderdate" label="ORDER DATE" width="150" />
                                        <el-table-column prop="ordernum" label="ORDER NUMBER" width="150" />
                                        <el-table-column prop="name" label="BUYER NAME" width="150" />
                                        <el-table-column prop="amount" label="AMOUNT" width="150" />
                                        <el-table-column prop="participantname" label="SELLER NAME" width="150" />
                                    </el-table>
                                </div>
                            </div>
                        </div>
                    </el-form>
                </div>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="eventBreakDown=false">Close</el-button>
                    </span>
                </template>
            </el-dialog>
            <div class="col-12 col-lg-6" v-if="profile!=null&&!editMode&&this.event.status=='Ended'&&this.event.organizerStoreSubTotal>0">
                <label class="top-label" style="color: #9A3324">Fundraiser Payout Information</label>
                <div class="title">
                    <div class="col-8 text-left">
                        <h6 class="font-weight-bold" style="word-break:keep-all;">To get started with your funraiser payout, go to your Profile>settings>Payment Methods</h6>
                        <div class=""><h6><router-link style="color: #9A3324" title="Go to Settings>Payment Methods" :to="{ name: 'Settings' }">Settings>PaymentMethods</router-link></h6></div>
                    </div>
                </div>
            </div>
        </div>       

         <!--<div class="col-12 col-lg-6" v-if="profile!=null&&!editMode&&this.event.status=='Ended'&&this.event.organizerStoreSubTotal>0">
        <label class="top-label">Fundraiser Payout Information - ACH Setup</label>
        <div class="title">
            <section class="form-title">
                <div v-if="profile.verifyMicroDepositsStatus!='OK'">
                    <h6 class="font-weight-bold" style="word-break:keep-all; text-align:center">To get started with your event payout we need to verify your Bank Details.  We will deposit two Micro-Deposits into your bank account.  Micro-deposits initiated, will take 1-2 days to settle to destination bank.</h6>
                    <div class="row col-12">
                        <el-checkbox class="" style="white-space:normal" @change="termscheckbox" v-model="terms" label="By checking this box you agree to our terms and conditions." size="small"></el-checkbox>
                    </div>
                    <div class="row col-12 ">

                        <el-button type="text" size="small" @click="termsDialogVisible = true" class="mr-2">Our Terms and Conditions</el-button>
                        <el-dialog v-model="termsDialogVisible" title="Our Terms and Conditions" :width="dialogWidth" center>
                            <div class="iframe-container">
                                <iframe src="https://coloradojackgivebacksa.blob.core.windows.net/termsandconditions/ColoradoJackGiveBack.TERMS.pdf" frameborder="0" width="100%" height="1000"></iframe>
                            </div>
                        </el-dialog>

                    </div>
                    <div class="row col-12 ">
                        <el-button type="text" size="small" @click="privacyDialogVisible = true" class="mr-2">Our Privacy Policy</el-button>
                        <el-dialog v-model="privacyDialogVisible" title="Privacy Policy" :width="dialogWidth" center>
                            <div class="iframe-container">
                                <iframe src="https://coloradojackgivebacksa.blob.core.windows.net/termsandconditions/Privacy Policy ColoradoJackGiveback.pdf" frameborder="0" width="100%" height="1000"></iframe>
                            </div>
                        </el-dialog>
                    </div>
                    <div class="row col-12">
                        <el-checkbox class="" style="white-space:normal" @change="changecheckbox" v-model="consent" label="By checking this box you agree to our partner terms and conditions." size="small"></el-checkbox>
                    </div>
                    <div class="row col-12 mt-1 mb-2">
                        <a href="https://www.dwolla.com/legal/tos/"><h6 style="font-size:12px; font-family:'Microsoft Sans Serif'">Dwolla Terms of Service</h6></a>
                    </div>
                    <div class="row col-12">
                        <a href="https://www.dwolla.com/legal/privacy/"><h6 style="font-size:12px; font-family:'Microsoft Sans Serif'">Dwolla Privacy Policy</h6></a>
                    </div>
                </div>
                <div v-else>
                    <h5 class="font-weight-bold" style="word-break:keep-all; text-align:center">Great it looks like we were able to verify your bank details!</h5>
                    <h6 class="font-weight-bold" style="word-break:keep-all; text-align:center">Please email <a href="mailto:info@coloradojackgiveback.com">info@coloradojackgiveback.com</a> to initate the ACH transfer to your account.</h6>
                </div>
            </section>
            <hr />
            <section v-if="profile.customerVerfiedStatus!='Created'&&profile.fundingSrcLinkStatus!='Created'&&profile.microDepositsStatus!='Created'" style="text-align:center">
                <el-button size="small" type="primary" style="background-color: #983222" plain @click="showACH = true" v-if="this.terms&&this.consent">Setup ACH - Bank Information</el-button>
            </section>
            <div>
                <section v-if="profile.fundingSrcLinkStatus!=null">
                    <div class="font-weight-bold">
                        Bank Link Status:
                    </div>
                    <div class="mt-1" style="color: #983222">
                        {{profile.fundingSrcLinkStatus}}
                    </div>
                </section>
                <section v-if="profile.microDepositsStatus!=null">
                    <div class="font-weight-bold">
                        Micro-Deposits Status:
                    </div>
                    <div class="mt-1" style="color: #983222">
                        {{profile.microDepositsStatus}}
                    </div>
                </section>
                <section v-if="profile.verifyMicroDepositsStatus!=null">
                    <div class="font-weight-bold">
                        Verification of Micro-Deposits Status:
                    </div>
                    <div class="mt-1" style="color: #983222">
                        {{profile.verifyMicroDepositsStatus}}
                    </div>
                </section>
                <section v-if="profile.microDepositsStatus=='Created'&&profile.verifyMicroDepositsStatus!='OK'">
                    <div>
                        <h6 class="font-weight-bold mb-3">Please enter the micro-deposits amounts that were deposited into you bank account then click 'Verify Micro-Deposits':</h6>
                        <el-form :model="profile" ref="profileForm2" :rules="profileRules2" :label-position="top" @submit.prevent>
                            <el-form-item label="Amount 1" prop="amount1">
                                <el-input v-model="profile.amount1" v-fancylabel></el-input>
                            </el-form-item>
                            <el-form-item label="Amount 2" prop="amount2">
                                <el-input v-model="profile.amount2" v-fancylabel></el-input>
                            </el-form-item>
                            <el-button type="primary" plain @click="verifyMicroDeposits">Verify Micro-Deposits</el-button>
                        </el-form>
                    </div>
                </section>
                <section v-if="profile.transactionHistory&&profile.microDepositsStatus=='Created'">
                    <div class="font-weight-bold">
                        View Payout Transaction History:
                    </div>
                    <div>
                        <el-table :data="profile.transactionHistory" style="width:100%" height="300">
                            <el-table-column prop="created" label="CREATED DATE" width="200" />
                            <el-table-column prop="status" label="STATUS" width="150" />
                            <el-table-column prop="amount.value" label="AMOUNT" width="150" />
                            <el-table-column prop="individualAchId" label="ACHID" width="150" />
                            <el-table-column prop="id" label="TRANSACTIONID" width="280" />
                        </el-table>
                    </div>
                    <div class="mt-1" style="color: #983222" v-for="transaction in profile.transactionHistory" :key="transaction.id">
                        <h6>transaction {{transaction.id}}</h6>
                    </div>
                </section>
            </div>
        </div>
    </div>-->

        <div class="row">
            <div class="col-12 col-lg-6">
                <label class="top-label">PARTICIPANTS</label>
                <el-badge :value="event.participantsCount" v-if="parseInt(event.participantsCount)>0" class="count">
                </el-badge>
                <div class="participants">
                    <div class="row m-0">
                        <div class="col-6"></div>
                        <div class="col-3 font-weight-bold text-right" style="font-size:14px">TARGET</div>
                        <div class="col-3  font-weight-bold text-right" style="font-size:14px">COLLECTED</div>
                    </div>
                    <hr class="m-1" />
                    <div style="overflow: auto; height: 250px; ">
                        <div class="row pt-1 pb-1 no-gutters" v-for="participant in participants" :key="participant.name">
                            <div class="col-6 elipssis pl-0">{{participant.name}}</div>
                            <div class="col-3 text-right">${{participant.targetAmount}}</div>
                            <div class="col-3 text-right">${{participant.amount}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <label class="top-label">BUYERS</label>
                <el-badge :value="event.donatorsCount" v-if="parseInt(event.donatorsCount)>0" class="count">
                </el-badge>
                <div class="donators">
                    <div class="row">
                        <div class="col-8"></div>
                        <div class="col-4 font-weight-bold text-right" style="font-size:14px">DONATION</div>
                    </div>
                    <hr class="m-1" />
                    <div style="overflow: auto; height: 250px; ">
                        <div class="row pt-1 pb-1 no-gutters" v-for="donator in donators" :key="donator.name">
                            <div class="col-8 elipssis">{{donator.name}}</div>
                            <div class="col-4 text-right">${{donator.amount}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr />
        <div class="row">
            <div class="col-12 col-lg-4 pl-0">
                <label class="font-weight-bold">Fundraiser Goal chart:</label>
                <DoughnutChart v-bind:chartData="eventChartData" v-bind:chartOptions="chartOptions" :key="eventChartData.labels" />
            </div>
            <div class="col-12 col-lg-4 pl-0">
                <label class="font-weight-bold">Participant totals chart:</label>
                <PieChart v-bind:chartData="participantsChartData" v-bind:chartOptions="chartOptions" :key="participantsChartData.labels" />
            </div>
            <div class="col-12 col-lg-4 pl-0">
                <label class="font-weight-bold">Donator totals chart:</label>
                <PieChart v-bind:chartData="donatorsChartData" v-bind:chartOptions="chartOptions" :key="donatorsChartData.labels" />
            </div>
        </div>
       
    </div>
</template>

<script>
    import axios from "axios"
    import { copyToClipboard } from "../assets/js/app.js";   
    import PieChart from "./PieChart"
    import DoughnutChart from './DoughnutChart.vue'
    import ChartDataLabels from 'chartjs-plugin-datalabels';
    import { mapGetters } from "vuex"
    import { initDwollaSdk, } from '@/services';

    export default {
        name: "EventDetail",
        components: { PieChart, DoughnutChart },
        data() {
            return {
                calculatedTotals: {
                    totalSales: 0,
                    eventSubtotal: 0,
                    totalElgibleProfit: 0,
                    shippingCosts: 0,
                    adminFee: 0,
                },
                eventBreakDown: false,
                errorMessage: '',
                event: {},
                eventEdit: {
                    imageCropData: {}
                },
                file: null,
                loading: false,
                editMode: false,
                accounttypes: [
                    { name: "Checking", id: "Checking" },
                    { name: "Savings", id: "Savings" }
                ],
                showACH: false,
                consent: false,
                terms: false,
                termsDialogVisible: false,
                profile: {
                    firstName: '',
                    lastName: '',
                    email: '',
                    type: 'personal',
                    address1: '',
                    address2: '',
                    city: '',
                    state: '',
                    postalCode: '',
                    dateOfBirth: '',
                    ssn: '',
                    accountType:'',
                    accountNumber: '',
                    routingNumber: '',
                    accountName: '',
                    customerVerfiedStatus: '',
                    fundingSrcLinkStatus: '',
                    microDepositsStatus: '',
                    verifyMicroDepositsStatus: '',
                    amount1: null,
                    amount2: null,
                    transactionHistory: []
                },
                profileRules: {
                    firstName: [
                        { required: true, message: 'First Name is required', trigger: 'blur' }
                    ],
                    LastName: [
                        { required: true, message: 'Last Name is required', trigger: 'blur' }
                    ],
                    email: [
                        { required: true, message: 'email is required', trigger: 'blur' }
                    ],
                    accountName: [
                        { required: true, message: 'Account Name is required', trigger: 'blur' }
                    ],
                    accountNumber: [
                        { required: true, type: 'string', message: 'Account Number is required', trigger: 'blur' }
                    ],
                    routingNumber: [
                        { required: true, type: 'string', pattern: /^(\d{3,9})$/g, message: 'Routing Number is required and should be 9 digits long', trigger: 'blur' }
                    ],
                    accountType: [
                        { required: true, message: 'Account Type is required', trigger: 'blur' }
                    ],                   
                    //ssn: [
                    //    //{ required: true, type: 'string', pattern: /^(? !0{ 4}) \\d{ 4}$/g, message: 'Last four digits of SSN is required from 0001-9999', trigger: 'blur' }
                    //    { required: true, message: 'Last four digits of SSN is required from 0001-9999', trigger: 'blur' }
                    //]
                },
                profileRules2: {
                    amount1: [
                        { required: true, type: 'string', pattern: /^[0-9]*(\.[0-9]{1,4})?$/g, message: 'Amount 1 is required', trigger: 'blur' }
                    ],
                    amount2: [
                        { required: true, type: 'string', pattern: /^[0-9]*(\.[0-9]{1,4})?$/g, message: 'Amount 2 is required', trigger: 'blur' }
                    ],                   
                },
                chartOptions: {
                    responsive: true,
                    animation: {
                        duration: 0
                    },
                    hover: {
                        animationDiration: 0
                    },
                    responsiveAnimationDuration: 0,
                    plugins: {
                        ChartDataLabels: {
                            labels: [
                                {
                                    render: 'label',
                                    position: 'outside'
                                },
                                {
                                    render: 'percentage'
                                }
                            ]
                        }
                    }
                },
            }
        },
        computed: {
            dialogWidth() {
                if (screen.width <= 576)
                    return "85%";
                else return "600px";
            },
            dialogTop() {
                if (screen.width <= 576)
                    return "0";
                else return "15vh";
            },
            screenWidth() {
                return screen.width;
            },
            participants() {
                let ps = [];
                if (this.event.participants != null) {
                    this.event.participants.split(',').forEach(p => {
                        let items = p.split('_');
                        ps.push({ name: items[0], targetAmount: items[1], amount: items[2] });
                    });
                }
                return ps;
            },
            donators() {
                let dn = [];
                if (this.event.donators != null) {
                    this.event.donators.split(',').forEach(p => {
                        let items = p.split('_');
                        dn.push({ name: items[0], amount: items[1] });
                    });
                }
                return dn;
            },
            donatorsOrders() {
                let dn = [];
                if (this.event.donatorsOrders != null) {
                    this.event.donatorsOrders.split(',').forEach(p => {
                        let items = p.split('_');
                        dn.push({ name: items[0], amount: items[1], ordernum: items[2], orderdate: items[3], participantname: items[4] });
                    });
                }
                return dn;
            },
            eventChartData() {
                let datatemplate = {
                    labels: [], datasets: [{
                        label: 'Event Totals',
                        data: [], backgroundColor: []
                    }]
                };
                if (this.event != null) {
                    let percentage = ((this.event.allStoresSubTotal * 100) / this.event.targetAmount);
                    datatemplate.labels.push('All Participant Stores Goal: $' + this.event.targetAmount);
                    datatemplate.datasets[0].data.push(100 - Math.round(percentage));
                    datatemplate.datasets[0].backgroundColor.push('#E46651');

                    datatemplate.labels.push('Fundraiser Dollars raised: $' + this.event.allStoresSubTotal);
                    datatemplate.datasets[0].data.push(Math.round(percentage));
                    datatemplate.datasets[0].backgroundColor.push('#41B883');
                }
                return datatemplate;
            },
            participantsChartData() {
                let datatemplate = {
                    labels: [], datasets: [{
                        data: [], backgroundColor: []
                    }]
                };
                if (this.event.participants != null) {
                    this.event.participants.split(',').forEach(p => {
                        if (p != null) {
                            let items = p.split('_');
                            if (items != null) {
                                var letters = '0123456789ABCDEF';
                                var color = '#';
                                for (var i = 0; i < 6; i++) {
                                    color += letters[Math.floor(Math.random() * 16)];
                                }
                                datatemplate.labels.push(items[0].trim());
                                datatemplate.datasets[0].data.push(items[2]);
                                datatemplate.datasets[0].backgroundColor.push(color);
                            }
                        }
                    });
                }
                return datatemplate;
            },
            donatorsChartData() {
                let datatemplate = {
                    labels: [], datasets: [{
                        data: [], backgroundColor: []
                    }]
                };
                if (this.event.donators != null) {
                    this.event.donators.split(',').forEach(p => {
                        if (p != null) {
                            let items = p.split('_');
                            if (items != null) {
                                var letters = '0123456789ABCDEF';
                                var color = '#';
                                for (var i = 0; i < 6; i++) {
                                    color += letters[Math.floor(Math.random() * 16)];
                                }
                                datatemplate.labels.push(items[0].trim());
                                datatemplate.datasets[0].data.push(items[1].toString());
                                datatemplate.datasets[0].backgroundColor.push(color);
                            }
                        }
                    });
                }
                return datatemplate;
            },
            ...mapGetters([
                'currentUserDetail'
            ])
        },
        mounted() {
                     
        },
        created() {
            this.getEventDetails();
            this.getDwollaDetails();

            this.profile.email = this.currentUserDetail.email;
            this.profile.firstName = this.currentUserDetail.firstName;
            this.profile.lastName = this.currentUserDetail.lastName;
         

        },        
        methods: {
            calcTotals() {
                this.calculatedTotals.totalSales = this.event.organizerStoreTotal;
                this.calculatedTotals.shippingCosts = parseFloat(this.event.organizerStoreTotal - this.event.organizerStoreSubTotal).toFixed(2);
                this.calculatedTotals.eventSubtotal = parseFloat(this.event.organizerStoreSubTotal - (this.event.organizerStoreTotal * .035)).toFixed(2);
                this.calculatedTotals.adminFee = parseFloat(this.event.organizerStoreTotal * .035).toFixed(2);
                this.calculatedTotals.totalElgibleProfit = parseFloat((this.event.organizerStoreSubTotal - (this.event.organizerStoreTotal * .035)) / 2).toFixed(2);
            },
            termscheckbox(val) {
                this.terms = val;
            },
            changecheckbox(val) {
                this.consent = val;
            },
            async getDwollaDetails() {
                await axios.get('/api/users/getdwollainfo/').then(response => {
                    this.profile.accountName = response.data.accountName;
                    this.profile.routingNumber = response.data.routingNumber;
                    this.profile.accountNumber = response.data.accountNumber;
                    this.profile.accountType = response.data.accountType;
                    this.profile.customerVerifiedStatus = response.data.customerVerifiedStatus;
                    this.profile.fundingSrcLinkStatus = response.data.fundingSrcLinkStatus;
                    this.profile.microDepositsStatus = response.data.microDepositsStatus;
                    this.profile.verifyMicroDepositsStatus = response.data.verifyMicroDepositsStatus;
                }).catch((error) => {
                });
                await axios.get('/api/users/getcustomerTransfers/').then(response => {
                    this.profile.transactionHistory = response.data._embedded.transfers;
                }).catch((error) => {
                });
            },
            async getEventDetails() {
                await axios.get('/api/events/detail/' + this.$route.params.id).then(response => {
                    this.event = response.data;
                    this.eventEdit.id = response.data.id;
                    this.eventEdit.title = this.event.title;
                    this.eventEdit.description = this.event.description;
                    this.calcTotals();
                }).catch((error) => {

                });
            },
            async getAddress() {
                await axios.get("/api/users/getaddress").then(response => {
                    this.profile.address1 = response.data.addressLine1;
                    this.profile.address2 = response.data.addressLine2;
                    this.profile.city = response.data.city;
                    this.profile.state = response.data.state;
                    this.profile.postalCode = response.data.zipCode;
                    //if (this.profile.address.country == null)
                    //    this.editMode = true;
                }).catch((error) => {
                });
            },
            shareOnFacebook() {
                window.open("https://www.facebook.com/sharer/sharer.php?u=" + this.event.publicUrl, "_blank");
            },
            shareOnTwitter() {
                window.open("https://twitter.com/share?url=" + this.event.publicUrl, "_blank");
            },
            copyCode() {
                copyToClipboard("eventCode");
            },
            setSelectedImage(file) {
                this.file = file;
            },
            inviteParticipants() {
                this.loading = true;
                var emails = [];
                this.invitedParticipants.forEach(p => {
                    if (p != '')
                        emails.push(p.email);
                });

                axios.post('/api/participants/invite/', { eventId: this.event.id, emails: emails }).then(response => {
                    this.loading = false;
                    this.invPartVisible = false;
                    this.$message({
                        message: 'Participants invited to the event.',
                        type: 'success'
                    });

                }).catch((error) => {
                    this.loading = false;
                });
            },
            submitChanges() {
                this.$refs["profileForm"].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        axios.post('/api/users/createfundingsource/', this.profile).then(response => {
                            this.showACH = false;
                            this.loading = false;
                            this.getDwollaDetails();
                        }).catch((error) => {
                            this.loading = false;
                            this.errorMessage = error.response.data;
                        });
                    }
                });
            },
            verifyMicroDeposits() {
                this.$refs["profileForm2"].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        axios.post('/api/users/verifymicrodeposits/', this.profile).then(response => {
                            this.profile.verifyMicroDepositsStatus = response.data;
                            this.editMode = false;
                            this.loading = false;
                        }).catch((error) => {
                            this.loading = false;
                            this.$message({
                                message: error,
                                type: 'error'
                            });
                        });
                    }

                });
            }
        }
    }
</script>

<style scoped>
    img {
        height: 300px;
        object-fit: cover;
    }

    .participants, .donators {
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 20px;
        min-height: 370px;
    }

    .top-label {
        position: relative;
        top: 22px;
        background: #fff;
        left: 15px;
        font-weight: bold;
        padding: 0 5px;
    }

    .count {
        top: 25px;
        left: 15px;
    }

    .title {
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 20px;
        /*min-height: 250px;*/
        flex-direction: inherit;
    }

    /* Small devices (landscape phones, 544px and up) */
    @media (min-width: 370px) {
        h5 {
            font-size: 1.0rem;
        }

        h6 {
            font-size: .80rem;
            /*font-weight: bold;*/
        }
        /*1rem = 16px*/
    }

    /* Small devices (landscape phones, 544px and up) */
    @media (min-width: 544px) {
        h5 {
            font-size: 1.00rem;
        }

        h6 {
            font-size: .90rem;
        }
        /*1rem = 16px*/
    }

    /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
    @media (min-width: 768px) {
        h5 {
            font-size: 1.25rem;
        }

        h6 {
            font-size: .95rem;
        }
        /*1rem = 16px*/
    }

    /* Large devices (desktops, 992px and up) */
    /*@media (min-width: 992px) {
        h5 {
            font-size: 1.5rem;
        }

        h6 {
            font-size: 1.0rem;
        }*/
        /*1rem = 16px*/
    /*}*/

    /* Extra large devices (large desktops, 1200px and up) */
    /*@media (min-width: 1600px) {
        h5 {
            font-size: 2.0rem;
        }

        h6 {
            font-size: 1.25rem;
        }*/
        /*1rem = 16px*/
    /*}*/

</style>

