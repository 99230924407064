<template>
    <div class="row center col-md-11 justify-content-center mb-5">
        <!--<div class="row col-md-11 center justify-content-end mr-5">
            <img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/popcornDrawing.png" class="img-fluid" />
            <img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/popcornDrawing.png" class="img-fluid" />
            <img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/popcornDrawing.png" class="img-fluid" />
        </div>-->
        <div class="col-md-11 center">
            <H1 class="mt-5 center" style="color: #983222;">THE CLASSIC</H1>
        </div>
    </div>
    <div class="row center col-md-11 justify-content-center mb-5">
        <div class="col-md-11 center">
            <h5 class="mt-3" style="color: black">The Classic is designed to fit the needs of most organizations, this offers you the opportunity to set your own pricing and keep the profits.  At the suggested retail price, you'll see a generous 50% profit from your Colorado Jack Giveback sales and we'll pay for the shipping.</h5>
        </div>
    </div>
    <hr class="col-md-12" style="color: #983222; height: 20px; background-color: #983222;" />
    <div class="row center col-md-10 justify-content-center mb-5">
        <div class="col-md-5 center" style="background-color: #983222">
            <H1 class="mt-3 center" style="color: white;">PRE-ORDER SALES</H1>
            <h5 class="mt-3" style="color: white; text-align:left">1. Group members will use individual order forms to take advance orders. Money is collected at the time of the sale and the group will use those funds to place your popcorn Order.</h5>
            <h5 class="mt-3" style="color: white; text-align: left">2. Tally all the individual sales to order exactly what your group has sold.  We'll fill your order and ship it direct to you.</h5>
            <h5 class="mt-3" style="color: white; text-align: left">3. When your order arrives, you'll need to sort it according to each order form so group members can make deliveries.</h5>
            <h5 class="mt-3" style="color: white; text-align: left">Note: A minimum of 48 cases is required for this option. Please use the online fundraising option if you are unsure you will sell 48 cases.</h5>
        </div>
        <div class="col-md-5 center">
            <img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/givebackclassic.webp" class="img-fluid" />
        </div>
    </div>
    <!--<hr class="col-md-12" style="color: #983222; height: 20px; background-color: #983222;" />
    <div class="row center col-md-11 justify-content-center ">
        <img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/BagsWood.webp" class="img-fluid col-md-11" />
    </div>-->
    <hr class="col-md-12 mb-5" style="color: #983222; height: 20px; background-color: #983222;" />

    <div class="row center col-md-11 justify-content-center mb-3">
        <div class="row justify-content-center mb-5">
            <h3 class="mt-3" style="color: #983222">Fill out the form below to get started!</h3>
        </div>
        <div class="res-card center shadow-card img-fluid col-md-11">
            <el-alert v-show="errorMessage.length>0" :title="errorMessage"
                      type="error"
                      effect="dark">
            </el-alert>
            <el-form :model="brochureevent" :rules="step1Rules" ref="step1Form" :label-position="top" hide-required-asterisk="true">
                <div class="row col-md-12 center">
                    <el-form-item label="First Name" prop="firstname" class="col-md-4">
                        <el-input v-model="brochureevent.firstname" v-fancylabel></el-input>
                    </el-form-item>
                    <el-form-item label="Last Name" prop="lastname" class="col-md-4">
                        <el-input v-model="brochureevent.lastname" v-fancylabel></el-input>
                    </el-form-item>
                    <el-form-item label="Email Address" prop="emailaddress" class="col-md-4">
                        <el-input v-model="brochureevent.emailaddress" v-fancylabel></el-input>
                    </el-form-item>
                </div>

                <div class=" row col-md-12 center">
                    <el-form-item label="Organization" prop="organization" class="col-md-6">
                        <el-input v-model="brochureevent.organization" v-fancylabel></el-input>
                    </el-form-item>
                    <el-form-item label="Address" prop="address" class="col-md-6">
                        <el-input v-model="brochureevent.address" v-fancylabel></el-input>
                    </el-form-item>
                </div>

                <div class=" row col-md-12 center">
                    <el-form-item label="City" prop="city" class="col-md-4">
                        <el-input v-model="brochureevent.city" v-fancylabel></el-input>
                    </el-form-item>
                    <el-form-item label="State" prop="state" class="col-md-4">
                        <el-input v-model="brochureevent.state" v-fancylabel></el-input>
                    </el-form-item>
                    <el-form-item label="Zip" prop="zip" class="col-md-4">
                        <el-input v-model="brochureevent.zip" v-fancylabel></el-input>
                    </el-form-item>
                </div>

                <div class=" row col-md-12 center">
                    <el-form-item label="How much you would like to raise?" prop="fundraisinggoal" class="col-md-4">
                        <el-input type="tel" v-model="brochureevent.fundraisinggoal" v-fancylabel></el-input>
                    </el-form-item>
                    <el-form-item label="How many paper Brochures are needed?" prop="numofbrochures" class="col-md-4">
                        <el-input type="tel" v-model="brochureevent.numofbrochures" v-fancylabel></el-input>
                    </el-form-item>
                    <!--<el-form-item label="Give Back Program" prop="givebackprogram" class="col-md-4">
                        <el-select v-model="brochureevent.givebackprogram" v-fancylabel>
                            <el-option v-for="item in givebackprogramlist"
                                       :key="item.value"
                                       :label="item.name"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>-->
                </div>

                <div class=" row col-md-12 center">
                    <el-form-item label="PICK A START DATE" prop="startdate" class="col-md-6">
                        <div v-fancylabel>
                            <el-date-picker v-model="brochureevent.startdate" style="width:100%"
                                            type="date"
                                            placeholder=""
                                            value-format="MM/DD/YYYY"
                                            format="MM/DD/YYYY">
                            </el-date-picker>
                        </div>
                    </el-form-item>
                    <el-form-item label="PICK A END DATE" prop="enddate" class="col-md-6">
                        <div v-fancylabel>
                            <el-date-picker v-model="brochureevent.enddate" style="width:100%"
                                            type="date"
                                            placeholder=""
                                            value-format="MM/DD/YYYY"
                                            format="MM/DD/YYYY">
                            </el-date-picker>
                        </div>
                    </el-form-item>
                </div>
                <el-button type="primary" @click="cancelBrochure">CANCEL</el-button>
                <el-button type="danger" style="background-color: #983222" @click="submitBrochure">SUBMIT</el-button>
            </el-form>
        </div>
    </div>
    <hr class="col-md-11 mb-5" style="color: #983222; height: 20px; background-color: #983222;" />
    <div class="row center col-md-12 justify-content-center">
        <img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/BagsWood.webp" class="img-fluid col-md-11" />
    </div>
    <div class="row center col-md-10 justify-content-center">
        <div class="row">
            <h2>FUNDRAISING NEVER TASTED SO GOOD!</h2>
        </div>
        <div class="row">
            <h5>Everyone loves popcorn.  With our special blend of topping, it's the perfect snack. With one taste, your family and friends will be asking for more!</h5>
        </div>
    </div>
    <!--<hr class="col-md-12 mb-5" style="color: #983222; height: 20px; background-color: #983222;" />
    <div class="row center col-md-11">
        <div class="col-md-12 center justify-content-center">
            <H2 class="center" style="color: #983222">SAY HELLO</H2>
        </div>
    </div>
    <div class="row col-md-12">
        <div class="col-md-5"></div>
        <div class="col-md-7 justify-content-md-start">
            <h3 class="mt-3 font-weight-bold" style="color: #983222;">EMAIL ADDRESS</h3>
            <h3 class="mt-2"><a href="mailto:info@coloradojackgiveback.com">info@coloradojackgiveback.com</a></h3>          
        </div>        
    </div>-->

</template>

<script>
    import axios from "axios"
    import router from "../router";
    import { mapGetters } from "vuex"

export default {
        name: 'Home',
        data() {
            return {
                errorMessage: '',
                loading: false,
                givebackprogramlist: [ { name: 'PreOrder', value: 1 }, { name: 'ShowAndSell', value: 2 }, { name: 'Both', value: 3 }],
                brochureevent: {
                    firstname: '',
                    lastname: '',
                    emailaddress: '',
                    organization: '',
                    address: '',
                    city: '',
                    state: '',
                    zip: '',
                    givebackprogram: 1,
                    startdate: '',
                    enddate: '',
                    fundraisinggoal: 0,
                    numofbrochures: 0
                },
                step1Rules: {
                    firstname: [
                        { required: true, message: 'First Name is Required', trigger: 'change' }
                    ],
                    lastname: [
                        { required: true, message: 'Last Name is Required', trigger: 'change' }
                    ],
                    emailaddress: [
                        { required: true, type: 'email', message: 'Invalid email address', trigger: 'blur' }
                    ],
                    organization: [
                        { required: true, message: 'Organization is required', trigger: 'change' }
                    ],
                    address: [
                        { required: true, message: 'Address is required', trigger: 'change' }
                    ],
                    city: [
                        { required: true, message: 'City is required', trigger: 'change' }
                    ],
                    state: [
                        { required: true, message: 'State is required', trigger: 'change' }
                    ],
                    zip: [
                        { required: true, message: 'Zip Code is required', trigger: 'change' }
                    ],
                    fundraisinggoal: [/* eslint-disable-next-line no-useless-escape*/
                        { required: true, type: 'string', pattern: /^\d+(\.\d{1,2})?$/g, message: 'Enter the Fundraising Goal in dollars: For Example 1000', trigger: 'blur' }
                    ],
                    numofbrochures: [/* eslint-disable-next-line no-useless-escape*/
                        { required: true, type: 'string', pattern: /^\d+(\.\d{1,2})?$/g, message: 'Enter the number of paper brochures needed: For Example 10', trigger: 'blur' }
                    ],
                    startdate: [
                        { type: 'date', required: true, message: 'Pick a start date for the event', trigger: 'change' }
                    ],
                    enddate: [
                        { type: 'date', required: true, message: 'Pick an end date for the event', trigger: 'change' }
                    ]                     
                },
            }
        },
        methods: {
            toNewEvent() {
                router.push("/Signup");
            },
            toLogin() {
                router.push("/login");
            },
            cancelBrochure() {
                router.push("/home");
            },
            async submitBrochure() {
                if (this.validateForm('step1Form') === true) {
                    this.loading = true;
                    var formData = new FormData();

                    for (const [key, value] of Object.entries(this.brochureevent)) {
                        formData.append(key, value);
                    }

                    await axios.post('/api/events/Brochure', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(response => {
                        this.loading = false;
                        this.brochureevent.id = response.data.id;
                        this.$message({
                            message: 'You should revieve an email shortly with more details for the your Giveback Fundraiser!',
                            type: 'success'
                        });
                        router.push('/Home');
                    }).catch((error) => {
                        this.errorMessage = error.response.data;
                        this.loading = false;
                    });
                }
            },
            validateForm(form) {
                let isValid = false;
                this.$refs[form].validate((valid) => {
                    isValid = valid;
                });
                return isValid;
            },
        },
        computed: {
            ...mapGetters([
                'isAuthenticated', 'cart', 'currentUserDetail'
            ])
        },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    
    /* page content */
    h1, h2, h4, h5 {
        /*font-family: Trebuchet MS;*/
        font-weight: bold;
        text-align: center;
    }

    h1 {
        font-size: 6rem;
        margin-top: 30vh;
    }

    h2 {
        font-size: 5rem;
    }

    /*.res-card {
        width: 1200px !important;
        padding: 20px 50px;
    }*/

    .multi-bg-example {
        width: 100%;
        height: 800px;
        background-image: url("https://coloradojackgivebacksa.blob.core.windows.net/publicwebsiteimages/5 Flavors with corn cobs.png");
        background-repeat: no-repeat;
        background-position: bottom right, left, right;
    }

    .jumbotron-welcome {
        background-image: url("https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/Kid.jpg");
        background-size: cover;
        color: white;
    }

    .res-card{
        width:1200px;
    }

    /* Small devices (landscape phones, 544px and up) */
    @media (min-width: 280px) {
        p {
            font-size: 1.0rem;
        }

        h1 {
            font-size: 1.5rem;
        }

        h2 {
            font-size: 1.0rem;
        }

        h3 {
            font-size: 1.0rem;
        }

        h5 {
            font-size: 1.0rem;
        }

        h6 {
            font-size: 1.0rem;
        }
        .el-button{
            width:auto;
        }
        .res-card {
            padding:  10px 10px;
        }
        /*1rem = 16px*/
    }

    /* Small devices (landscape phones, 544px and up) */
    @media (min-width: 700px) {
        p {
            font-size: 1.0rem;
        }

        h1 {
            font-size: 1.5rem;
        }

        h2 {
            font-size: 1.0rem;
        }

        h3 {
            font-size: 1.0rem;
        }

        h5 {
            font-size: 1.0rem;
        }

        h6 {
            font-size: 1.0rem;
        }

        .res-card {          
            padding: 10px 10px;
        }
       
        /*1rem = 16px*/
    }

    /* Small devices (landscape phones, 544px and up) */
    @media (min-width: 940px) {
        p {
            font-size: 1.0rem;
        }

        h1 {
            font-size: 1.5rem;
        }

        h2 {
            font-size: 1.25rem;
        }

        h3 {
            font-size: 1.1rem;
        }

        h5 {
            font-size: 1.00rem;
        }

        h6 {
            font-size: 1.0rem;
        }
        .el-button {
            width: auto;
        }

        .res-card {
            padding: 10px 10px;
        }
        /*1rem = 16px*/
    }

   
    /* Large devices (desktops, 992px and up) */
    @media (min-width: 1200px) {
        p {
            font-size: 1.25rem;
        }

        h1 {
            font-size: 2.5rem;
        }

        h2 {
            font-size: 2.0rem;
        }

        h3 {
            font-size: 1.5rem;
        }

        h5 {
            font-size: 1.5rem;
        }

        h6 {
            font-size: 1.25rem;
        }
        /*1rem = 16px*/
    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1800px) {
        p {
            font-size: 1.50rem;
        }

        h1 {
            font-size: 3.0rem;
        }

        h2 {
            font-size: 2.5rem;
        }

        h5 {
            font-size: 2.0rem;
        }

        h6 {
            font-size: 1.60rem;
        }
        /*1rem = 16px*/
    }

    .video-container {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px;
        height: 0;
        overflow: hidden;
    }

    .video-container iframe, .video-container object, .video-container embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
  

</style>
