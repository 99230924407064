<template>   
    <!--<el-upload class="avatar-uploader" v-if="!fileSrc"  :show-file-list="true" :auto-upload="false" :on-change="fileSelected" drag>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
    </el-upload>-->
    <el-upload class="upload-button" :show-file-list="false" :auto-upload="false" :on-change="fileSelected" v-if="showUploadButton">
        <el-button type="warning" size="small" icon="el-icon-document" >{{fileSrc?'CHANGE':'UPLOAD CSV FILE'}}</el-button>
    </el-upload>
</template>

<script>
    export default {
        name: 'FileUploader',
        components: {},
        props: {
            file: String,
            showUploadButton: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                fileSrc:'',               
            }
        },
        methods: {
            fileSelected(file) {
                this.fileSrc = URL.createObjectURL(file.raw);               
                this.$emit('file-selected', file);
            },            
        },
        mounted() {
            this.fileSrc = this.file;
        }
    }
    </script>
<style>
    .avatar-uploader .el-upload.el-upload--text, .avatar-uploader .el-upload.el-upload--text .el-upload-dragger {
        width: 100%;
    }
    .upload-button {
        /*position: absolute;*/
       /* top: 5px;
        left:5px;*/
        box-shadow: 1px 1px 5px #555;
    }
    .upload-button button {
          
    }
    .image-container, .image-container >div{
        max-width: 100% !important;
        height: 100% !important;
    }
    .image-container > div > img {
            width: 100% !important;
            height: 100% !important;
            object-fit:cover;
     }
    .cropper-container.cropper-bg {
        width: 100% !important;
        height: 100% !important;
    }
    /*.image-container img {
        width: 100% !important;
        height: 100% !important;
        object-fit:cover;
    }*/
</style>