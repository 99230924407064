<template>    
    <div class="container">
        <img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/coloradojackorders.webp" alt="background image" class="background-pic" />
        <div class="center">
            
            <div v-if="openStores.length < 1" class="row pt-sm-5 pt-5" style="margin:0 auto; justify-content:center">
                <div class="col-12 col-sm-6 c-pointer text-center mb-5">
                    <el-form :model="signup" ref="" :label-position="top" hide-required-asterisk="true" show-message="false" @submit.prevent>
                        <section class="form-title">
                            <h3 style="font-weight:500">Join an in-progress Fundraiser with a code:</h3>
                            <h6 class="mt-3" style="font-weight:500">Ask your fundraising cordinator for the code if you don't already have it</h6>
                        </section>

                        <el-alert class="mt-4" v-show="errorMessage" :title="errorMessage" type="error" effect="dark" :closable="false">
                        </el-alert>

                        <el-form-item>
                            <el-input type="text" style="width: 100%;" v-model="signup.code" maxlength="6" show-word-limit class="email-code" autocomplete="off" v-focus v-fancylabel></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="danger" style="background-color: #983222" @click="checkinvitecode()" class="float-center">SUBMIT</el-button>
                        </el-form-item>
                        
                        <div v-if="!this.runningEvent" class="row" style="margin:0 auto; justify-content:center">
                            <section class="form-title" style="justify-content:center;text-align:center">
                                <div style="text-align: center;">
                                    <h3 style="font-weight: 500; text-align: center;">Create a new Fundraiser</h3>
                                </div>
                                <div style="text-align: center;">
                                    <h6>Or you can start your own Colorado Jack Giveback fundraiser, by clicking the button below!</h6>
                                </div>
                            </section>
                            <div style="text-align: center;">
                                <el-button type="danger" style="background-color: #983222; width:100%; padding-bottom: 20px" size="large" @click="createNew">Create a new fundraiser!</el-button>
                            </div>
                        </div>
                    </el-form>
                </div>
            </div>

            <hr />

            <div class="row mt-sm-5 pt-sm-5 mt-5 pt-5" style="margin:0 auto; justify-content:center">
                <div v-if="this.runningEvent || closedEvents.length > 0 || comingEvents.length > 0" class="col-12 col-sm-6 c-pointer text-center mb-5">
                    <el-card @click="toEventsDashboard()">
                        <div class="dashboard-icon">
                            <i class="el-icon-time" style="font-size: 10rem; color: #983222"></i>
                        </div>
                        <div class="h1 font-weight-bold mt-5">
                            See my Fundraisers
                        </div>
                        <div class="h5 font-weight-light mt-5">
                            View current and past fundraisers
                        </div>
                    </el-card>
                </div>
                <div v-if="openStores.length > 0 || closedStores.length > 0" class="col-12 col-sm-6 c-pointer text-center">
                    <el-card @click="toStoresDashboard()">
                        <div class="dashboard-icon">
                            <i class="el-icon-s-shop" style="font-size: 10rem; color: #983222"></i>
                        </div>
                        <div class="h1 font-weight-bold mt-5">See my Online Stores</div>
                        <div class="h5 font-weight-light mt-5 ">
                            View current and past online stores
                        </div>
                    </el-card>
                </div>
            </div>

        </div>          
    </div>
</template>

<script>
    import axios from "axios"
    import router from "../router";
    export default {
        name: "DashboardOptions",
        data() {
            return {
                signup: {
                    code: ''
                },
                errorMessage: '',
                proceed: false,
                loading: false,
                closedEvents: [],
                comingEvents: [],
                runningEvent: null,               
                closedStores: [],
                openStores: [],
                upcomingStores: [],
            }
        },
        methods: {
            toEventsDashboard() {
                router.push('/o/dashboard');
            },
            toStoresDashboard() {
                router.push('/p/dashboard');
            },
            toSignUpParticipant() {
                //router.push('/p/signup/' + signup.code);
            }, 
            async checkinvitecode() {
                if (this.signup.code) {
                    await axios.get('/api/participants/CheckAppInviteCode/' + this.signup.code).then(response => {
                        this.proceed = true;
                        this.errorMessage = '';
                        router.push('/p/signup/' + this.signup.code);
                    }).catch((error) => {
                        this.errorMessage = error.response.data;
                    });
                }
            },
            createNew() {
                router.push('/NewEvent');
            }
        },
        async mounted() {        
            //check for owned events and stores
             this.loading = true;
             axios.get("/api/events").then(response => {
                 const runningEvents = response.data.filter(e => e.status === "Running");
                 if (runningEvents.length > 0)
                     this.runningEvent = runningEvents[0];
                 this.comingEvents = response.data.filter(i => i.status === "Draft");
                 this.closedEvents = response.data.filter(i => i.status === "Ended");
             });
             this.loading = false;
        },
        created() {
            this.loading = true;
            axios.get("/api/stores").then(response => {
                this.upcomingStores = response.data.filter(i => i.status === "Draft");
                this.openStores = response.data.filter(e => e.status === "Open");
                this.closedStores = response.data.filter(i => i.status === "Closed");
            });
            this.loading = false;
        }
    }
</script>

<style scoped>
    
</style>