<template>
    <div class="res-card center">
        <el-form :model="emailData" :rules="emailRules" ref="forgorPasswordForm" :label-position="top" hide-required-asterisk="true" show-message="false" @submit.prevent>
            <section class="mb-3">
                <div class="h4">Enter your email address</div>
                <div class="h6">we will send you a link to reset your password</div>
            </section>
            <el-form-item label="EMAIL" prop="email">
                <el-input type="text" v-model="emailData.email" v-focus v-fancylabel v-on:keyup.enter="sendChangePasswordLink"></el-input>
            </el-form-item>            
            <el-form-item>
                <el-button type="primary" @click="sendChangePasswordLink" :loading="loading" class="float-left">SEND</el-button>
                <el-button type="primary" @click="cancel"  >CANCEL</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import axios from "axios"
    import router from "../router"; // our vue router instance

    export default {
        name: 'ForgotPassword',
        components: {  },
        data() {
            return {
                loading: false,
                emailData: {},
                emailRules: {
                    email: [
                        { required: true, type: 'email', message: 'Invalid email address', trigger: 'blur' }
                    ]
                }
            }
        },
        methods: {
            sendChangePasswordLink() {
                this.$refs["forgorPasswordForm"].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        var formData = new FormData();
                        formData.append("Email", this.emailData.email);
                        axios.post("/api/users/sendresetpasswordlink", formData).then(response => {
                            this.loading = false;
                            if (response.data == "1") {
                                this.$message({
                                    message: 'A change password link has been sent to your email address.',
                                    type: 'success'
                                });
                            }
                            else {
                                if (response.data == "UserNotFound") {
                                    this.$message({
                                        message: 'The user could not be found.',
                                        type: 'error'
                                    });
                                }
                                else {
                                    this.$message({
                                        message: 'Please try again later.',
                                        type: 'error'
                                    });
                                }
                            }
                        }).catch((error) => {
                            this.loading = false;
                        });
                    }
                });
            },
            cancel() {
                router.push("/login");
            }
        },
        computed: {
           
        },
        mounted() {
          
        },
        created() {
          
        }
    }
</script>


<style>
  
</style>
